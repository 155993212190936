import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import CryptoJS from "crypto-js";

import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import Holdings from "./Holdings";
import OpenPositions from "./OpenPositions";
import PortfolioEmptyStateIcon from "../../assests/Empty_State_Portfolio.svg";
import OpenPositonAccountData from "./OpenPositonAccountData";
import TokenExpireBrokarModal from "../StockRecommendation/TokenExpireBrokarModal";

const style = {
  analyticsHead:
    " text-[12px] lg:text-sm text-[#000000]/80 leading-[22px] font-poppins",
  analyticsValue:
    "text-black text-[18px] lg:text-[26px] font-semibold font-poppins",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
  selected:
    "flex items-center text-[16px] leading-[14px] lg:text-[18px]  font-poppins text-black font-semibold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[16px] leading-[14px] font-medium font-poppins lg:text-[18px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};
const HomePage = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState();
  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDeatils();
  }, [userEmail]);

  const [brokerStatus, setBrokerStatus] = useState(
    userDetails ? userDetails.connect_broker_status : null
  );

  useEffect(() => {
    if (userDetails && userDetails.user_broker !== undefined) {
      setBrokerStatus(userDetails && userDetails.connect_broker_status);
    }
  }, [userDetails, brokerStatus]);

  const clientCode = userDetails && userDetails.clientCode;
  const apiKey = userDetails && userDetails?.apiKey;
  const broker = userDetails && userDetails.user_broker;
  const jwtToken = userDetails && userDetails.jwtToken;
  const my2pin = userDetails && userDetails.my2Pin;
  const secretKey = userDetails && userDetails.secretKey;
  const viewToken = userDetails && userDetails?.viewToken;
  const sid = userDetails && userDetails?.sid;
  const serverId = userDetails && userDetails?.serverId;
  const mobileNumber = userDetails && userDetails?.phone_number;
  const panNumber = userDetails && userDetails?.panNumber;
  // user login
  const dateString = userDetails && userDetails.token_expire;

  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");
  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");
  const userId = userDetails && userDetails._id;

  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [allHoldingsData, setAllHoldingsData] = useState();

  const getAllHoldings = () => {
    if (broker === "IIFL Securities") {
      if (clientCode) {
        let data = JSON.stringify({
          clientCode: clientCode,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}iifl/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "ICICI Direct") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          sessionToken: jwtToken,
          secretKey: checkValidApiAnSecret(secretKey),
          exchange: "NSE",
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}icici/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Upstox") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          accessToken: jwtToken,
          apiSecret: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}upstox/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Angel One") {
      if (apiKey && jwtToken) {
        let data = JSON.stringify({
          apiKey: apiKey,
          jwtToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Zerodha") {
      if (jwtToken) {
        let data = JSON.stringify({
          apiKey: "b0g1r806oitsamoe",
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}zerodha/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Kotak") {
      if (jwtToken) {
        let data = JSON.stringify({
          consumerKey: checkValidApiAnSecret(apiKey),
          consumerSecret: checkValidApiAnSecret(secretKey),
          accessToken: jwtToken,
          viewToken: viewToken,
          sid: sid,
          serverId: serverId,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}kotak/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            // console.log("res", response);
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const [funds, setFunds] = useState("");
  const getAllFunds = () => {
    if (broker === "IIFL Securities") {
      if (clientCode) {
        let data = JSON.stringify({
          clientCode: clientCode,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}iifl/margin`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "ICICI Direct") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          sessionToken: jwtToken,
          secretKey: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}icici/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Upstox") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          accessToken: jwtToken,
          apiSecret: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}upstox/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Zerodha") {
      if (jwtToken) {
        let data = JSON.stringify({
          apiKey: "b0g1r806oitsamoe",
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}zerodha/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Kotak") {
      if (jwtToken) {
        let data = JSON.stringify({
          consumerKey: checkValidApiAnSecret(apiKey),
          consumerSecret: checkValidApiAnSecret(secretKey),
          accessToken: jwtToken,
          viewToken: viewToken,
          exchange: "NSE",
          segment: "CASH",
          product: "ALL",
          sid: sid,
          serverId: serverId,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}kotak/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            // console.log("res", response);
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      if (apiKey && jwtToken) {
        let data = JSON.stringify({
          apiKey: apiKey,
          jwtToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    getAllHoldings();
    getAllFunds();
  }, [userDetails]);

  const profitAndLoss = parseFloat(allHoldingsData?.totalprofitandloss).toFixed(
    2
  );
  const pnlPercentage = parseFloat(allHoldingsData?.totalpnlpercentage).toFixed(
    2
  );

  const [holdingsData, setHoldingsData] = useState([]);
  const [positionsData, setpositionsData] = useState([]);

  const getAllHoldingsData = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/portfolio/specific-user?email=${userEmail}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setHoldingsData(
          response.data.filteredData ? response.data.filteredData : []
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllPositionsData = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/positions/specific-user?email=${userEmail}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setpositionsData(response.data.data ? response.data.data : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllHoldingsData();
    getAllPositionsData();
  }, [userEmail]);

  const last_updated_date =
    holdingsData.length !== 0 ? holdingsData?.[0]["last_updated"] : today;

  const fullURL = window.location.href;

  const [openTokenExpiryModel, setOpenTokenExpiryModel] = useState(true);
  const [selectedTab, setSelectedTab] = useState("holdings");

  const [stockRecoNotExecuted, setStockRecoNotExecuted] = useState([]);

  const getAllTrades = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/user/trade-reco-for-user?user_email=${userEmail}`,
    };
    axios
      .request(config)
      .then((response) => {
        const filteredTrades = response?.data?.trades.filter((trade) => {
          return trade.trade_place_status === "recommend";
        });

        setStockRecoNotExecuted(filteredTrades);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  const [ltp, setLtp] = useState([]);
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    // Initialize WebSocket connection
    const newSocket = io("wss://ccxt.alphaquark-case.com", {
      transports: ["websocket"],
      query: { EIO: "4" },
    });
    newSocket.on("connect", () => {
      // console.log("Connected to Socket.IO server");
    });
    newSocket.on("market_data", (data) => {
      setLtp((prev) => {
        // console.log("ptev", prev);
        const index = prev.findIndex(
          (item) => item.tradingSymbol === data.stockSymbol
        );
        if (index !== -1) {
          const newLtp = [...prev];
          newLtp[index] = {
            ...newLtp[index],
            lastPrice: data.last_traded_price,
          };
          return newLtp;
        } else {
          const updatedLtp = [
            ...prev,
            {
              tradingSymbol: data.stockSymbol,
              lastPrice: data.last_traded_price,
            },
          ];
          return updatedLtp;
        }
      });
    });
    newSocket.on("disconnect", () => {
      // console.log("Disconnected from Socket.IO server");
    });

    const pingInterval = setInterval(() => {
      if (newSocket.connected) {
        newSocket.emit("ping");
      }
    }, 20000);

    setSocket(newSocket);

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  const getCurrentPrice = () => {
    if (!positionsData || positionsData.length === 0) {
      return;
    }
    const uniqueSymbols = new Set();
    const symbolsToSubscribe = positionsData
      .map((trade) => ({
        exchange: trade.Exchange,
        tradingSymbol: trade.Symbol,
      }))
      .filter((order) => {
        if (uniqueSymbols.has(order.tradingSymbol)) {
          return false;
        } else {
          uniqueSymbols.add(order.tradingSymbol);
          return true;
        }
      });

    symbolsToSubscribe.forEach((symbol) => {
      const data = {
        symbol: symbol.tradingSymbol,
        exchange: symbol.exchange,
      };

      axios
        .post("https://ccxt.alphaquark-case.com/websocket/subscribe", data)
        .then((response) => {
          // console.log(`Subscribed to ${symbol.tradingSymbol}:`, response.data);
        })
        .catch((error) => {
          // console.error(`Error subscribing to ${symbol.tradingSymbol}:`, error);
        });
    });
  };

  // const getCurrentPriceForHolding = () => {
  //   if (!holdingsData || holdingsData.length === 0) {
  //     return;
  //   }
  //   const uniqueSymbols = new Set();
  //   const symbolsToSubscribe = holdingsData?.holding
  //     .map((trade) => ({
  //       exchange: trade.exchange,
  //       tradingSymbol: trade.symbol,
  //     }))
  //     .filter((order) => {
  //       if (uniqueSymbols.has(order.tradingSymbol)) {
  //         return false;
  //       } else {
  //         uniqueSymbols.add(order.tradingSymbol);
  //         return true;
  //       }
  //     });

  //   symbolsToSubscribe.forEach((symbol) => {
  //     const data = {
  //       symbol: symbol.tradingSymbol,
  //       exchange: symbol.exchange,
  //     };

  //     axios
  //       .post("https://ccxt.alphaquark-case.com/websocket/subscribe", data)
  //       .then((response) => {
  //         // console.log(`Subscribed to ${symbol.tradingSymbol}:`, response.data);
  //       })
  //       .catch((error) => {
  //         // console.error(`Error subscribing to ${symbol.tradingSymbol}:`, error);
  //       });
  //   });
  // };

  useEffect(() => {
    if (positionsData && positionsData.length > 0) {
      getCurrentPrice();
      // getCurrentPriceForHolding();
    }
  }, [positionsData, userDetails]);

  const getLTPForSymbol = (symbol) => {
    const ltpOne = ltp.find((item) => {
      return item.tradingSymbol === symbol;
    })?.lastPrice;
    return ltpOne ? ltpOne.toFixed(2) : "-";
  };

  const isFundsEmpty =
    !funds || Object.keys(funds).length === 0 || !funds.availablecash;

  const holdingDataLength =
    holdingsData &&
    holdingsData?.filter(
      (item) => item.user_broker === userDetails?.user_broker
    );
  const positionsDataLength =
    positionsData &&
    positionsData?.filter(
      (item) => item.user_broker === userDetails?.user_broker
    );
  return (
    <>
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <>
          {brokerStatus === "connected" ? (
            <div className="flex flex-col relative  w-full max-h-[calc(100vh-60px)] overflow-auto custom-scroll md:h-[calc(100vh-90px)] lg:min-h-screen lg:overflow-hidden  bg-[#f9f9f9] ">
              <div className=" lg:px-12">
                <div className="px-4 lg:px-0 text-balck text-[22px] leading-[30px] mt-4 lg:mt-8 font-sans font-bold">
                  Portfolio Overview
                </div>
                <div className="px-4 lg:px-0 text-sm text-[#000000]/70 leading-[20px] font-light font-poppins">
                  Get instant insight into your investments with a comprehensive
                  portfolio overview.
                </div>
                <OpenPositonAccountData
                  style={style}
                  allHoldingsData={allHoldingsData}
                  profitAndLoss={profitAndLoss}
                  pnlPercentage={pnlPercentage}
                  funds={funds}
                  last_updated_date={last_updated_date}
                />
              </div>

              {holdingsData &&
              holdingsData.length === 0 &&
              positionsData &&
              positionsData.length === 0 ? (
                <div className="lg:flex flex-col items-center justify-center lg:h-[calc(100vh-260px)] mt-6 ">
                  <div className="pt-10 lg:pt-0 flex flex-col items-center justify-center">
                    <div className="text-xl lg:text-3xl font-semibold font-sans flex items-center justify-center">
                      No Portfolio Data
                    </div>
                    <p className="text-[14px] text-center lg:text-lg font-normal text-[#000000]/60 font-sans flex items-center justify-center">
                      Place Orders now to seize opportunities & book profits
                    </p>
                    <Link
                      to="/stock-recommendation"
                      className="mt-4 py-2 px-6 bg-black text-[15px] lg:text-lg  text-white font-medium rounded-md cursor-pointer"
                    >
                      View Trade Advices
                    </Link>
                  </div>
                </div>
              ) : (
                <>
                  <div className="sticky -top-[1px] bg-[#f9f9f9]  grid grid-cols-2 min-h-[50px]  md:space-x-8 md:flex flex-row lg:px-12   border-b border-[#000000]/10   lg:h-[50px] font-poppins lg:mt-4">
                    <button
                      className={`
                          ${
                            selectedTab === "holdings"
                              ? ` ${style.selected}`
                              : ` ${style.unselected}`
                          }
                        px-4 lg:min-w-[120px] justify-center`}
                      onClick={() => {
                        setSelectedTab("holdings");
                      }}
                    >
                      Holdings
                      {holdingDataLength.length > 0 && (
                        <span
                          className={`ml-2 pt-[1px] px-[4px] flex items-center text-[11px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[28px] h-5 rounded-full ${
                            selectedTab !== "holdings"
                              ? "bg-[#000000]/30"
                              : "bg-[#E43D3D]"
                          }`}
                        >
                          {holdingDataLength ? holdingDataLength.length : ""}
                        </span>
                      )}
                    </button>
                    <button
                      className={`
                          ${
                            selectedTab === "positions"
                              ? ` ${style.selected}`
                              : ` ${style.unselected}`
                          }
                        px-4 lg:min-w-[120px] justify-center`}
                      onClick={() => {
                        setSelectedTab("positions");
                      }}
                    >
                      Positions
                      {positionsDataLength?.length > 0 && (
                        <span
                          className={`ml-2 pt-[1px] px-[4px] flex items-center text-[11px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[28px] h-5 rounded-full ${
                            selectedTab !== "positions"
                              ? "bg-[#000000]/30"
                              : "bg-[#E43D3D]"
                          }`}
                        >
                          {positionsDataLength
                            ? positionsDataLength?.length
                            : ""}
                        </span>
                      )}
                    </button>
                  </div>

                  <div
                    className={` h-full pb-6 lg:relative lg:flex flex-col lg:h-[calc(100vh-305px)] lg:pb-0 lg:mt-0`}
                  >
                    {selectedTab === "positions" && (
                      <OpenPositions
                        data={
                          positionsData &&
                          positionsData?.filter(
                            (item) =>
                              item.user_broker === userDetails.user_broker
                          )
                        }
                        getLTPForSymbol={getLTPForSymbol}
                      />
                    )}
                    {selectedTab === "holdings" && (
                      <Holdings
                        userDetails={userDetails}
                        data={
                          holdingsData &&
                          holdingsData?.filter(
                            (item) =>
                              item.user_broker === userDetails.user_broker
                          )
                        }
                        getLTPForSymbol={getLTPForSymbol}
                      />
                    )}
                  </div>
                </>
              )}

              {(todayDate > expireTokenDate && openTokenExpiryModel === true) ||
              (isFundsEmpty && openTokenExpiryModel === true) ? (
                <TokenExpireBrokarModal
                  openTokenExpireModel={openTokenExpiryModel}
                  setOpenTokenExpireModel={setOpenTokenExpiryModel}
                  userId={userId && userId}
                  apiKey={apiKey}
                  secretKey={secretKey}
                  checkValidApiAnSecret={checkValidApiAnSecret}
                  clientCode={clientCode}
                  my2pin={my2pin}
                  panNumber={panNumber}
                  mobileNumber={mobileNumber}
                  broker={broker}
                  getUserDeatils={getUserDeatils}
                />
              ) : null}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center space-y-4 lg:space-y-6 relative  w-full lg:min-h-screen  bg-[#f9f9f9] ">
              <div className="flex items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
                <img
                  src={PortfolioEmptyStateIcon}
                  alt="Empty State Portfolio Icon"
                  className="w-[60px] lg:w-[70px]"
                />
              </div>
              <div className="flex flex-col space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
                <div className="flex flex-col space-y-3">
                  <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                    No Portfolio Data.
                  </div>
                  <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                    Place Orders now to seize opportunities & book profits.
                  </div>
                </div>

                {stockRecoNotExecuted && stockRecoNotExecuted.length > 0 && (
                  <Link
                    to="/stock-recommendation"
                    className="mt-6 py-2 px-6 bg-black text-[18px] lg:text-lg  text-white font-medium rounded-md cursor-pointer"
                  >
                    View Investment Advices
                  </Link>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HomePage;
