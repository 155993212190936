import React, { useState, useEffect } from "react";
import moment from "moment";
import { Pie, PieChart } from "recharts";
import toast, { Toaster } from "react-hot-toast";
import { io } from "socket.io-client";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  CandlestickChartIcon,
  EyeIcon,
  EyeOffIcon,
  Info,
  Share2Icon,
  TimerIcon,
  TrendingUpIcon,
} from "lucide-react";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

import VolatilityIcon from "../../assests/volatility.svg";
import Alpha100 from "../../assests/alpha-100.png";
import ChartData from "../../assests/chart_data.png";

import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/ui/chart";
import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import UserStrategySubscribeModal from "./UserStrategySubscribeModal";
import RecommendationSuccessModal from "../StockRecommendation/RecommendationSuccessModal";
import ConnectBroker from "../UserHomePage/connectBroker";
import { TokenExpireModal } from "../StockRecommendation/TokenExpireModal";

const style = {
  selected:
    "flex items-center text-[16px] md:text-[18px]  font-sans text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[16px] md:text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
};

const chartData = [
  { shares: "microsoft", value: 45, fill: "#f59e0b" },
  { shares: "reliance", value: 10, fill: "#f43f5e" },
  { shares: "larsen", value: 20, fill: "#d946ef" },
  { shares: "deepak", value: 25, fill: "#f97316" },
];

const chartConfig = {
  value: {
    label: "Equity Distribution",
  },
  microsoft: {
    label: "Microsoft",
    color: "#f59e0b",
  },
  reliance: {
    label: "Reliance",
    color: "#f43f5e",
  },
  larsen: {
    label: "Larsen & Tourbo",
    color: "#d946ef",
  },
  deepak: {
    label: "Deepak Nitride",
    color: "#f97316",
  },
};

const StrategyDetails = () => {
  const { fileName } = useParams();
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const [userDetails, setUserDetails] = useState();
  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDeatils();
  }, [userEmail, server.server.baseUrl]);

  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();
  const [selectedDataTab, setSelectedDataTab] = useState("performance");
  const [openSubscribeModel, setOpenSubscribeModel] = useState(false);

  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);

  const getStrategyDetails = () => {
    if (fileName) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${fileName?.replaceAll(
            /_/g,
            " "
          )}`
        )
        .then((res) => {
          const portfolioData = res.data;
          setStrategyDetails(portfolioData);
          if (
            portfolioData &&
            portfolioData.model &&
            portfolioData.model.rebalanceHistory.length > 0
          ) {
            const latest = portfolioData.model.rebalanceHistory.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            )[0];
            setLatestRebalance(latest);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  const borderColors = [
    "amber-500",
    "rose-500",
    "fuchsia-500",
    "orange-500",
    "green-500",
    "yellow-500",
    "purple-500",
  ];
  const subscribed =
    strategyDetails?.subscribed_by.filter((email) => email === userEmail)
      .length > 0;

  const [ltp, setLtp] = useState([]);
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    // Initialize WebSocket connection
    const newSocket = io("wss://ccxt.alphaquark-case.com", {
      transports: ["websocket"],
      query: { EIO: "4" },
    });
    newSocket.on("connect", () => {
      // console.log("Connected to Socket.IO server");
    });
    newSocket.on("market_data", (data) => {
      setLtp((prev) => {
        const index = prev.findIndex(
          (item) => item.tradingSymbol === data.stockSymbol
        );
        if (index !== -1) {
          const newLtp = [...prev];
          newLtp[index] = {
            ...newLtp[index],
            lastPrice: data.last_traded_price,
          };
          return newLtp;
        } else {
          const updatedLtp = [
            ...prev,
            {
              tradingSymbol: data.stockSymbol,
              lastPrice: data.last_traded_price,
            },
          ];
          return updatedLtp;
        }
      });
    });
    newSocket.on("disconnect", () => {
      // console.log("Disconnected from Socket.IO server");
    });

    const pingInterval = setInterval(() => {
      if (newSocket.connected) {
        newSocket.emit("ping");
      }
    }, 20000);

    setSocket(newSocket);

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  const getCurrentPrice = () => {
    if (!latestRebalance.adviceEntries || latestRebalance.adviceEntries === 0) {
      return;
    }
    const uniqueSymbols = new Set();
    const symbolsToSubscribe = latestRebalance?.adviceEntries
      .map((trade) => ({
        exchange: trade.exchange,
        tradingSymbol: trade.symbol,
      }))
      .filter((order) => {
        if (uniqueSymbols.has(order.tradingSymbol)) {
          return false;
        } else {
          uniqueSymbols.add(order.tradingSymbol);
          return true;
        }
      });

    symbolsToSubscribe.forEach((symbol) => {
      const data = {
        symbol: symbol.tradingSymbol,
        exchange: symbol.exchange,
      };

      axios
        .post("https://ccxt.alphaquark-case.com/websocket/subscribe", data)
        .then((response) => {
          // console.log(`Subscribed to ${symbol.tradingSymbol}:`, response.data);
        })
        .catch((error) => {
          // console.error(`Error subscribing to ${symbol.tradingSymbol}:`, error);
        });
    });
  };

  useEffect(() => {
    if (
      latestRebalance?.adviceEntries &&
      latestRebalance?.adviceEntries.length > 0
    ) {
      getCurrentPrice();
    }
  }, [latestRebalance, userEmail]);

  const getLTPForSymbol = (symbol) => {
    const ltpOne = ltp.find((item) => {
      return item.tradingSymbol === symbol;
    })?.lastPrice;
    return ltpOne ? ltpOne.toFixed(2) : "-";
  };

  const [broker, setBroker] = useState("");
  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
    }
  }, [userDetails]);

  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const clientCode = userDetails && userDetails?.clientCode;
  const apiKey = userDetails && userDetails?.apiKey;
  const jwtToken = userDetails && userDetails?.jwtToken;
  const my2pin = userDetails && userDetails?.my2Pin;
  const secretKey = userDetails && userDetails?.secretKey;
  const userId = userDetails && userDetails?._id;

  const [brokerModel, setBrokerModel] = useState(false);
  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(null);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  // user login

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const handleIiflLogin = () => {
    setLoginLoading(true);
    let data = JSON.stringify({
      clientCode: clientCode,
      password: password,
      my2pin: my2pin,
      userId: userId,
    });

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/iifl/generate-session`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoginLoading(false);
        getUserDeatils();
        setOpenTokenExpireModel(false);
        toast.success(
          "You have been successfully logged in to IIFL Securities",
          {
            duration: 5000,
            style: {
              background: "white",
              color: "#16a085",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "16px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
      })
      .catch((error) => {
        const result = error.response.data.response;
        setLoginLoading(false);
        toast.error(`${result.message}`, {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  useEffect(() => {
    if (showSuccessMsg) {
      setShowSuccessMsg(true);
      const toRef = setTimeout(() => {
        setShowSuccessMsg(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showSuccessMsg]);
  return (
    <div className="flex flex-col max-h-[calc(100vh-60px)]  lg:min-h-screen  bg-[#F9F9F9] ">
      {/* Bottom Fixed Subscribe Rebalance Button for Mobile View  */}
      <Toaster position="top-center" reverseOrder={true} />
      <div className="w-full grid grid-cols-12 h-[calc(100vh-120px)] overflow-y-auto lg:min-h-full ">
        {/* Left  Side Strategy Details Part */}
        <div className="col-span-12 px-4 lg:col-span-8 xl:col-span-9 xl:px-[60px]">
          <div className="flex flex-col space-y-6 py-10 ">
            <div className="flex space-x-6">
              {strategyDetails?.image ? (
                <div className="flex items-center justify-center">
                  <img
                    src={`${server.server.baseUrl}${strategyDetails.image}`}
                    alt={strategyDetails?.modal_name}
                    className="h-[50px] w-[50px] rounded-md"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src={Alpha100}
                    alt="Alpha 100 Strategy"
                    className="h-[50px] w-[50px] rounded-md"
                  />
                </div>
              )}
              <div className="flex justify-center flex-col">
                <div className="flex items-center space-x-4">
                  <h3 className="text-[18px] font-sans font-bold capitalize text-[#000000]">
                    {fileName?.replaceAll(/_/g, " ")}
                  </h3>
                </div>
              </div>
            </div>

            <p className="text-[14px] font-normal font-poppins text-[#000000]/60">
              {strategyDetails?.overView}
            </p>
          </div>

          <div className="flex flex-col space-y-6 pb-8 lg:hidden">
            <div className="flex justify-evenly  divide-x-2 bg-[#E4FDD5]/50 ">
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  CAGR
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +186%
                </p>
              </div>

              <div className="flex flex-col space-y-1 items-center  justify-center h-[80px] px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  Returns (2 yrs)
                </div>
                <p className="text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +400%
                </p>
              </div>
            </div>

            <div className="w-full flex flex-col ">
              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img src={VolatilityIcon} className="h-[26px] w-[26px]" />
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Volatility :
                  <p className="ml-2 text-[14px] text-[#000000] font-poppins font-medium ">
                    Low
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <div className="flex items-center justify-center text-[12px] pt-0.5 rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[24px] w-[24px] ">
                  10
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Strategy Score
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <TrendingUpIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Rebalance Frequency :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Weekly
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/10 py-4 space-x-3">
                <TimerIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Duration :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Short Terms
                  </p>
                </div>
              </div>

              <div className="flex items-center border-none py-4 space-x-3">
                <CandlestickChartIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  No. of Trades :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    15K Trades
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <div className="flex items-center  justify-between border-b-[1px] border-[#000000]/10">
              <div className="flex  space-x-6 ">
                <div
                  className={`
              ${
                selectedDataTab === "performance"
                  ? style.selected
                  : style.unselected
              } flex items-center justify-center md:w-[120px] py-2 lg:py-1`}
                  onClick={() => {
                    setSelectedDataTab("performance");
                  }}
                >
                  Performance
                </div>
                <div
                  className={`
                ${
                  selectedDataTab === "distribution"
                    ? style.selected
                    : style.unselected
                } flex items-center justify-center md:w-[120px] py-2 lg:py-1`}
                  onClick={() => {
                    setSelectedDataTab("distribution");
                  }}
                >
                  Distribution
                </div>
              </div>
              <Share2Icon className="h-5 w-5 text-[#000000] " />
            </div>

            {selectedDataTab === "performance" && (
              <div className="flex items-center h-full w-full">
                <img src={ChartData} alt="Alpha 100 Performance Chart" />
              </div>
            )}

            {selectedDataTab === "distribution" && (
              <div className="flex flex-col space-y-6">
                <div className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                  Check Latest Rebalance Updates issued by the Manager.
                  <span className="ml-2 cursor-pointer text-[14px] text-blue-500 font-normal font-poppins underline">
                    View Rebalance History
                  </span>
                </div>

                <div className="grid grid-cols-2 gap-y-5 md:gap-y-0  md:grid-cols-3 lg:w-[656px] lg:divide-x-2 divide-[#000000]/10 bg-[#f1f0f0] rounded-[4px] py-4 lg:px-0">
                  <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                    <h4 className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                      Last Rebalance
                    </h4>
                    <p className="text-[16px] text-[#000000] font-medium font-poppins">
                      {moment(strategyDetails?.last_updated).format(
                        "MMM DD, YYYY"
                      )}
                    </p>
                  </div>
                  <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                    <h4 className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                      Next Rebalance
                    </h4>
                    <p className="text-[16px] text-[#000000] font-medium font-poppins">
                      {moment(strategyDetails?.nextRebalanceDate).format(
                        "MMM DD, YYYY"
                      )}
                    </p>
                  </div>
                  <div className="flex flex-col pl-4 md:pl-0 md:items-center space-y-1">
                    <h4 className="text-[14px] text-[#000000]/60 font-normal font-poppins">
                      Rebalance Frequency
                    </h4>
                    <p className="text-[16px] text-[#000000] font-medium font-poppins">
                      {strategyDetails?.frequency}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row lg:space-x-8 ">
                  <div className="flex flex-col lg:w-[400px]">
                    <div className="grid grid-cols-5 border-b-[1px] border-[#000000]/10 py-3 px-4 ">
                      <div className="col-span-3 text-[14px] text-[#000000]/60 font-normal font-poppins">
                        Equity
                      </div>
                      <div className="flex justify-end col-span-2 text-[14px] text-[#000000]/60 font-normal font-poppins">
                        Weightage (%)
                      </div>
                    </div>
                    {latestRebalance?.adviceEntries?.map((ele, i) => {
                      return (
                        <div
                          className="relative grid grid-cols-5 border-b-[1px] border-b-[#000000]/10 border-l-[4px]  border-l-amber-500   py-4 px-8"
                          key={i}
                        >
                          <div className="col-span-3 text-[15px] text-[#000000]/85 font-normal font-poppins">
                            {ele.symbol}
                          </div>
                          <div className="flex justify-end col-span-2 text-[15px] text-[#000000]/85 font-normal font-poppins">
                            {ele.value * 100}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <ChartContainer
                    config={chartConfig}
                    className="mx-auto aspect-square max-h-[300px] h-[280px] "
                  >
                    <PieChart>
                      <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent hideLabel />}
                      />
                      <Pie data={chartData} dataKey="value" nameKey="shares" />
                    </PieChart>
                  </ChartContainer>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col space-y-6 py-10 ">
            <div className=" text-[18px] font-semibold font-sans text-[#000000]">
              Why this Strategy?
            </div>

            <p className="text-[14px] font-normal font-poppins text-[#000000]/60">
              {strategyDetails?.whyThisStrategy}
            </p>
          </div>
        </div>

        {/* Right Side Metrics Part */}

        <div className="hidden lg:block xl:col-span-3 lg:col-span-4">
          <div className="flex flex-col space-y-6 px-6 py-10">
            {subscribed === true ? (
              <button className="w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6">
                Subscribed
              </button>
            ) : (
              <button
                onClick={() => setOpenSubscribeModel(true)}
                className="w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6"
              >
                Subscribe Rebalance
              </button>
            )}

            <div className="flex justify-evenly  divide-x-2 bg-[#E4FDD5]/50 ">
              <div className="flex flex-col space-y-1 items-center justify-center h-[80px] px-4 md:px-2 xxl:px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  CAGR
                </div>
                <p className="text-[20px] md:text-lg xl:text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +186%
                </p>
              </div>

              <div className="flex flex-col space-y-1 items-center  justify-center h-[80px] px-4 md:px-2 xxl:px-4 ">
                <div className="text-[14px] text-[#000000]/70 font-poppins font-semibold ">
                  Returns (2 yrs)
                </div>
                <p className="text-[20px] md:text-lg xl:text-[20px] text-[#18B13F] font-poppins font-semibold ">
                  +400%
                </p>
              </div>
            </div>

            <div className="w-full flex flex-col py-6">
              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <div className="flex items-center justify-center">
                  <img src={VolatilityIcon} className="h-[26px] w-[26px]" />
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Volatility :
                  <p className="ml-2 text-[14px] text-[#000000] font-poppins font-medium ">
                    Low
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <div className="flex items-center justify-center text-[14px] pt-0.5 rounded-full ring-[3px] ring-[#16A05E] font-poppins font-semibold h-[30px] w-[30px] ">
                  10
                </div>
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Strategy Score
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <TrendingUpIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Rebalance Frequency :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Weekly
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <TimerIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  Duration :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    Short Terms
                  </p>
                </div>
              </div>

              <div className="flex items-center border-b-[1px] border-[#000000]/20 py-4 space-x-3">
                <CandlestickChartIcon className="h-5 w-5 text-[#000000] " />
                <div className="flex items-center  text-[14px] text-[#000000]/80 font-poppins font-medium ">
                  No. of Trades :
                  <p className="ml-2 text-[14px] text-[#000000]/70 font-poppins font-normal ">
                    15K Trades
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:hidden flex items-center w-full h-[80px] shadow-[0px_-4px_4px_0px_#0000000f] px-4">
        <button
          onClick={() => setOpenSubscribeModel(true)}
          className=" w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6"
        >
          Subscribe Rebalance
        </button>
      </div>

      {openSubscribeModel === true ? (
        <UserStrategySubscribeModal
          setOpenSubscribeModel={setOpenSubscribeModel}
          userEmail={userEmail}
          getStrategyDetails={getStrategyDetails}
          strategyDetails={strategyDetails}
          fileName={fileName}
          latestRebalance={latestRebalance}
          userDetails={userDetails}
          setOpenSucessModal={setOpenSucessModal}
          setOrderPlacementResponse={setOrderPlacementResponse}
          getLTPForSymbol={getLTPForSymbol}
          setBrokerModel={setBrokerModel}
        />
      ) : null}
      {openSuccessModal && (
        <RecommendationSuccessModal
          setOpenSucessModal={setOpenSucessModal}
          orderPlacementResponse={orderPlacementResponse}
          setStockDetails={setStrategyDetails}
          openSuccessModal={openSuccessModal}
        />
      )}
      {brokerModel === true ? (
        <ConnectBroker
          uid={userDetails && userDetails._id}
          userDetails={userDetails && userDetails}
          setBrokerModel={setBrokerModel}
          getUserDeatils={getUserDeatils}
          broker={broker}
          setBroker={setBroker}
          brokerModel={brokerModel}
        />
      ) : null}

      {openTokenExpireModel === true ? (
        <TokenExpireModal
          openTokenExpireModel={openTokenExpireModel}
          setOpenTokenExpireModel={setOpenTokenExpireModel}
        >
          <div className="flex flex-col items-center justify-center space-y-2">
            <div className="flex items-center justify-center rounded-full p-2 ">
              <Info className="text-[#000000]/50 w-16 h-16 " />
            </div>
          </div>
          <div className="flex space-x-4 items-center justify-center  text-black text-center text-2xl font-bold mt-4 mb-4">
            <span className="text-black text-center text-xl font-medium  font-poppins">
              Please login to your broker to continue investments
            </span>
          </div>
          <div className="md:flex flex-col space-y-2">
            {broker === "IIFL Securities" ? (
              <div className="md:flex flex-col space-y-2">
                <div className={style.inputStartDiv}>
                  <input
                    id="clientCode"
                    placeholder="Client Code"
                    type="text"
                    value={clientCode}
                    disabled
                    className={`${style.inputBox}`}
                  />
                  <label
                    htmlFor="clientCode"
                    className={`${style.firstHeading} ${style.labelFloat}`}
                  >
                    Client Code
                  </label>
                </div>
                <div className={style.inputStartDiv}>
                  <input
                    id="my2pin"
                    placeholder="My2Pin"
                    type="text"
                    value={my2pin}
                    disabled
                    className={`${style.inputBox}`}
                  />
                  <label
                    htmlFor="my2pin"
                    className={`${style.firstHeading} ${style.labelFloat}`}
                  >
                    My2Pin
                  </label>
                </div>
                <div className={`relative ${style.inputStartDiv} `}>
                  <input
                    id="pass"
                    placeholder="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={`${style.inputBox}`}
                  />
                  <label
                    htmlFor="pass"
                    className={`${style.firstHeading} ${style.labelFloat}`}
                  >
                    Password
                  </label>
                  {showPassword ? (
                    <EyeIcon
                      onClick={() => setShowPassword(false)}
                      className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                    />
                  ) : (
                    <EyeOffIcon
                      onClick={() => setShowPassword(true)}
                      className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>

          {broker === "IIFL Securities" ? (
            <div
              className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer"
              onClick={handleIiflLogin}
            >
              {loginLoading === true ? (
                <LoadingSpinner />
              ) : (
                <span className="text-[16px] text-white font-semibold font-poppins">
                  Login to {broker ? broker : ""}
                </span>
              )}
            </div>
          ) : broker === "ICICI Direct" ? (
            <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
              <a
                href={`https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(
                  checkValidApiAnSecret(apiKey ? apiKey : "")
                )}`}
              >
                <span className="text-[16px] text-white font-semibold font-poppins">
                  Login to {broker ? broker : ""}
                </span>
              </a>
            </div>
          ) : broker === "Upstox" ? (
            <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
              <a
                href={`https://api.upstox.com/v2/login/authorization/dialog?response_type=code&client_id=${checkValidApiAnSecret(
                  apiKey
                )}&redirect_uri=https://iamclearmind.alphaquark-case.com/stock-recommendation&state=ccxt`}
              >
                <span className="text-[16px] text-white font-semibold font-poppins">
                  Login to {broker ? broker : ""}
                </span>
              </a>
            </div>
          ) : broker === "Zerodha" ? (
            <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
              <a href="https://kite.zerodha.com/connect/login?api_key=b0g1r806oitsamoe&v=3">
                <span className="text-[16px] text-white font-semibold font-poppins">
                  Login to {broker ? broker : ""}
                </span>
              </a>
            </div>
          ) : (
            <div className="mt-6 lg:mt-0 text-center bg-[#000000] text-[#ffffff]  py-3 px-4 font-bold  text-lg font-poppins rounded-lg cursor-pointer">
              <a href="https://smartapi.angelbroking.com/publisher-login?api_key=43Ecd6Ng">
                <span className="text-[16px] text-white font-semibold font-poppins">
                  Login to {broker ? broker : ""}
                </span>
              </a>
            </div>
          )}
        </TokenExpireModal>
      ) : null}
    </div>
  );
};

export default StrategyDetails;
