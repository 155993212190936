import React, { useEffect, useState } from "react";
import handIcon from "../../assests/hand_icon.svg";
import termsIcon from "../../assests/terms_icon.svg";
import rationaleIcon from "../../assests/rationale_icon.png";
import groupIcon from "../../assests/group_icon.svg";
import clientsIcon from "../../assests/clients_icon.svg";
import AdminSettingsModal from "./AdminSettingsModal";
import axios from "axios";
import server from "../../utils/serverConfig";
import { useNavigate } from "react-router-dom";

const AdminEmptyState = ({ adminEmail ,disclaimerData }) => {

  const navigate = useNavigate()

  console.log("disclaimeradata",disclaimerData)
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [data, setData] = useState();

  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/admin/${adminEmail}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDeatils();
  }, [adminEmail]);

  console.log("Admin data", data);

  const openSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const closeSettingsModal = () => {
    setShowSettingsModal(false);
  };

  const handleViewClientList = () => {
    navigate('/admin/client-list')
  }

  return (
    <div className="min-h-screen flex flex-col w-full items-center justify-center bg-[#F9F9F9]">
      <div className="flex flex-col items-center justify-center">
        <h1 className="font-sans text-[22px] font-bold leading-[40px] text-center">
          Welcome to Clearmind
        </h1>
        <p className="font-poppins text-[#7C8086] text-[16px] font-normal leading-[22px] text-center mt-2 lg:max-w-[600px]">
          Start by adding a disclaimer, terms, and rationale. Then, add clients
          to enable recommendations and group organization.
        </p>

        <div className="flex gap-4 mt-6 max-w-[900px]">
          <div className={`${disclaimerData && disclaimerData?.hasOwnProperty("disclaimer") ? 'bg-green-200' : ''} border-[2px] border-dashed lg:max-w-[300px] border-[#000000]/15 rounded-[4px] bg-white  px-4 py-8 flex flex-col items-center justify-center`}>
            <img src={handIcon} alt="Add Disclaimer" width={40} height={40} />
            <h3 className="font-poppins text-[16px] font-semibold leading-[24px] text-center mt-2">
              Add Disclaimer
            </h3>
            <p className="font-poppins text-[#7C8086] text-[12px] font-normal leading-[20px] text-center mt-1">
              Set a clear disclaimer to inform users of important legal details.
            </p>
          </div>

          <div className={`${disclaimerData && disclaimerData?.hasOwnProperty("termsAndConditions") ? 'bg-green-200' : ''} border-[2px] border-dashed lg:max-w-[300px] border-[#000000]/15 rounded-[4px] bg-white  px-4 py-8 flex flex-col items-center justify-center`}>
            <img src={termsIcon} alt="Add T&C" width={40} height={40} />
            <h3 className="font-poppins text-[16px] font-semibold leading-[24px] text-center mt-2">
              Add T&C
            </h3>
            <p className="font-poppins text-[#7C8086] text-[12px] font-normal leading-[20px] text-center mt-1">
              Define the rules and guidelines for using your services.
            </p>
          </div>

          <div className={`${disclaimerData && disclaimerData?.hasOwnProperty("rationale") ? 'bg-green-200' : ''} border-[2px] border-dashed lg:max-w-[300px] border-[#000000]/15 rounded-[4px] bg-white  px-4 py-8 flex flex-col items-center justify-center`}>
            <img
              src={rationaleIcon}
              alt="Add Default Rationale"
              width={55}
              height={55}
            />
            <h3 className="font-poppins text-[16px] font-semibold leading-[24px] text-center mt-2">
              Add Default Rationale
            </h3>
            <p className="font-poppins text-[#7C8086] text-[12px] font-normal leading-[20px] text-center mt-1">
              Provide a default rationale to explain the basis of your advice.
            </p>
          </div>
        </div>

        <div className="flex justify-center gap-4 mt-6 max-w-[900px]">
          <div onClick={handleViewClientList} className=" cursor-pointer border-[2px] border-dashed lg:max-w-[300px] border-[#000000]/15 rounded-[4px] bg-white  px-4 py-8 flex flex-col items-center justify-center">
            <img src={clientsIcon} alt="Add Clients" width={40} height={40} />
            <h3 className="font-poppins text-[16px] font-semibold leading-[24px] text-center mt-2">
              Add Clients
            </h3>
            <p className="font-poppins text-[#7C8086] text-[12px] font-normal leading-[20px] text-center mt-1">
              Add clients to start sending advice(s) and forming groups.
            </p>
          </div>

          <div onClick={handleViewClientList} className=" cursor-pointer border-[2px] border-dashed lg:max-w-[300px] border-[#000000]/15 rounded-[4px] bg-white  px-4 py-8 flex flex-col items-center justify-center">
            <img
              src={groupIcon}
              alt="Organize Clients in Groups"
              width={40}
              height={40}
            />
            <h3 className="font-poppins text-[16px] font-semibold leading-[24px] text-center mt-2">
              Organize Clients in Groups
            </h3>
            <p className="font-poppins text-[#7C8086] text-[12px] font-normal leading-[20px] text-center mt-1">
              Group clients for targeted and efficient communication.
            </p>
          </div>
        </div>

        <button
          onClick={openSettingsModal}
          className="mt-6 px-6 py-3 bg-black text-white font-poppins text-[16px] font-semibold rounded shadow-lg"
        >
          Setup your Account
        </button>
      </div>

      {showSettingsModal && (
        <AdminSettingsModal
          adminEmail={adminEmail}
          closeSettingsModal={closeSettingsModal}
        />
      )}
    </div>
  );
};

export default AdminEmptyState;
