import React, { useState, useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import CryptoJS from "crypto-js";
import { io } from "socket.io-client";
import { TrendingUp } from "lucide-react";

import { auth } from "../../firebase";
import server from "../../utils/serverConfig";

import NewStockCard from "./NewStockCard";
import StepGuideScreen from "./StepGuideScreen";
import StepGuideModal from "./StepGuideModal";
import ConnectBroker from "../UserHomePage/connectBroker";
import UpdateUserDeatils from "../UserHomePage/UpdateUserDetails";
import ReviewTradeModel from "./ReviewTradeModel";

import RecommendationSuccessModal from "./RecommendationSuccessModal";
import ZerodhaReviewModal from "./ZerodhaReviewModal";
import { IgnoreTradeModal } from "./IgnoreTradeModal";
import TokenExpireBrokarModal from "./TokenExpireBrokarModal";
import IsMarketHours from "../../utils/isMarketHours";

const style = {
  selected:
    "flex items-center text-[12px] leading-[14px] lg:text-[16px]  font-poppins text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[12px] leading-[14px] font-medium font-poppins lg:text-[16px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
};

const defaultRationale = `This recommendation is based on a comprehensive analysis of the
        company's growth potential and value metrics. This recommendation
        also accounts for potential future risks, ensuring a balanced
        approach to maximizing returns while mitigating uncertainties.
        Please contact your advisor for any queries.`;

const StockRecommendation = ({ getAllTradesUpdate }) => {
  // user details fetch
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState();
  const getUserDeatils = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDeatils();
  }, [userEmail, server.server.baseUrl]);

  const [brokerModel, setBrokerModel] = useState(null);
  const [brokerStatus, setBrokerStatus] = useState(
    userDetails ? userDetails.connect_broker_status : null
  );

  const [updateUserDetails, setUpdateUserDetails] = useState(false);
  useEffect(() => {
    if (userDetails && userDetails.user_broker !== undefined) {
      setBrokerStatus(userDetails && userDetails.connect_broker_status);
    }
  }, [userDetails, brokerStatus]);

  // all recommendation
  const [stockRecoNotExecuted, setStockRecoNotExecuted] = useState([]);
  const [ignoredTrades, setIgnoredTrades] = useState([]);

  const getAllTrades = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/user/trade-reco-for-user?user_email=${userEmail}`,
    };
    axios
      .request(config)
      .then((response) => {
        const filteredTrades = response?.data?.trades.filter((trade) => {
          return trade.trade_place_status === "recommend";
        });

        setStockRecoNotExecuted(filteredTrades);

        const filteredIgnoredTrades = response?.data?.trades.filter((trade) => {
          return trade.trade_place_status === "ignored";
        });

        setIgnoredTrades(filteredIgnoredTrades);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  const [stockDetails, setStockDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const clientCode = userDetails && userDetails.clientCode;
  const apiKey = userDetails && userDetails.apiKey;
  const jwtToken = userDetails && userDetails.jwtToken;
  const my2pin = userDetails && userDetails.my2Pin;
  const secretKey = userDetails && userDetails.secretKey;
  const viewToken = userDetails && userDetails?.viewToken;
  const sid = userDetails && userDetails?.sid;
  const serverId = userDetails && userDetails?.serverId;
  const mobileNumber = userDetails && userDetails?.phone_number;
  const panNumber = userDetails && userDetails?.panNumber;

  const [broker, setBroker] = useState("");
  const [openReviewTrade, setOpenReviewTrade] = useState(false);
  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();
  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(null);

  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
    }
  }, [userDetails]);

  const dateString = userDetails && userDetails.token_expire;
  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");
  const userId = userDetails && userDetails._id;
  const checkValidApiAnSecret = (data) => {
    const bytesKey = CryptoJS.AES.decrypt(data, "ApiKeySecret");
    const Key = bytesKey.toString(CryptoJS.enc.Utf8);
    if (Key) {
      return Key;
    }
  };

  const updatePortfolioData = () => {
    if (broker === "IIFL Securities") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}iifl/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Kotak") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}kotak/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Upstox") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}upstox/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "ICICI Direct") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}icici/user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else if (broker === "Angel One") {
      let data = JSON.stringify({
        user_email: userEmail,
      });

      let config = {
        method: "post",

        url: `${server.ccxtServer.baseUrl}user-portfolio`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const placeOrder = () => {
    setLoading(true);
    if (broker === "IIFL Securities") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        clientCode: clientCode,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/iifl/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("res--------", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          updatePortfolioData();
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          getCartAllStocks();
        })
        .catch((error) => {
          console.error(`Error placing order for stock `, error);
          setLoading(false);
          toast.error("Something went wrong. Please try again later", {
            duration: 6000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
          // Handle error for individual stock
        });
    } else if (broker === "ICICI Direct") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        secretKey: checkValidApiAnSecret(secretKey),
        jwtToken: jwtToken,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/icici/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          updatePortfolioData();
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          getCartAllStocks();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else if (broker === "Upstox") {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: apiKey,
        jwtToken: jwtToken,
        secretKey: secretKey,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/upstox/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          getAllTrades();
          updatePortfolioData();
          getCartAllStocks();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else if (broker === "Kotak") {
      let data = JSON.stringify({
        apiKey: apiKey,
        secretKey: secretKey,
        jwtToken: jwtToken,
        viewToken: viewToken,
        sid: sid,
        serverId: serverId,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/kotak/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("kotak order place res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          getCartAllStocks();
          updatePortfolioData();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    } else {
      setOpenReviewTrade(true);
      let data = JSON.stringify({
        apiKey: apiKey,
        jwtToken: jwtToken,
        trades: stockDetails,
      });
      let orderConfig = {
        method: "post",
        url: `${server.server.baseUrl}api/trading/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(orderConfig)
        .then((response) => {
          // console.log("angel one order place res=======", response);
          setLoading(false);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          if (getAllTradesUpdate) {
            getAllTradesUpdate(); // Ensure this is being called correctly
          }
          updatePortfolioData();
          getCartAllStocks();
        })
        .catch((error) => {
          console.log("err", error);
          toast.error(
            "There was an issue in placing the trade, please try again after sometime or contact your advisor",
            {
              duration: 6000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            }
          );
          setLoading(false);
        });
    }
  };

  const [isStepGuideLoading, setIsStepGuideLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsStepGuideLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const [showStepGuideModal, setShowStepGuideModal] = useState(false);
  // ignore trade
  const [openIgnoreTradeModel, setOpenIgnoreTradeModel] = useState(false);
  const [stockIgnoreId, setStockIgnoreId] = useState();
  const [ignoreLoading, setIgnoreLoading] = useState(false);
  const [ignoreText, setIgnoreText] = useState("");
  const handleIgnoredTrades = (id) => {
    setIgnoreLoading(true);
    let data = JSON.stringify({
      uid: id,
      trade_place_status: "ignored",
      reason: ignoreText,
    });

    // Second API request to place the order
    let orderConfig = {
      method: "put",
      url: `${server.server.baseUrl}api/recommendation`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(orderConfig)
      .then((response) => {
        toast.success("You have successfully ignored your trade", {
          duration: 5000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setIgnoreLoading(false);
        setOpenIgnoreTradeModel(false);
        getAllTrades();
      })
      .catch((error) => {
        console.error(`Error placing order for stock `, error);
        setLoading(false);
      });
  };

  const [ltp, setLtp] = useState([]);
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    // Initialize WebSocket connection
    const newSocket = io("wss://ccxt.alphaquark-case.com", {
      transports: ["websocket"],
      query: { EIO: "4" },
    });
    newSocket.on("connect", () => {
      // console.log("Connected to Socket.IO server");
    });
    newSocket.on("market_data", (data) => {
      setLtp((prev) => {
        const index = prev.findIndex(
          (item) => item.tradingSymbol === data.stockSymbol
        );
        if (index !== -1) {
          const newLtp = [...prev];
          newLtp[index] = {
            ...newLtp[index],
            lastPrice: data.last_traded_price,
          };
          return newLtp;
        } else {
          const updatedLtp = [
            ...prev,
            {
              tradingSymbol: data.stockSymbol,
              lastPrice: data.last_traded_price,
            },
          ];
          return updatedLtp;
        }
      });
    });
    newSocket.on("disconnect", () => {
      // console.log("Disconnected from Socket.IO server");
    });

    const pingInterval = setInterval(() => {
      if (newSocket.connected) {
        newSocket.emit("ping");
      }
    }, 20000);

    setSocket(newSocket);

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  const getCurrentPrice = () => {
    if (!stockRecoNotExecuted || stockRecoNotExecuted.length === 0) {
      return;
    }
    const uniqueSymbols = new Set();
    const symbolsToSubscribe = stockRecoNotExecuted
      .map((trade) => ({
        exchange: trade.Exchange,
        tradingSymbol: trade.Symbol,
      }))
      .filter((order) => {
        if (uniqueSymbols.has(order.tradingSymbol)) {
          return false;
        } else {
          uniqueSymbols.add(order.tradingSymbol);
          return true;
        }
      });

    symbolsToSubscribe.forEach((symbol) => {
      const data = {
        symbol: symbol.tradingSymbol,
        exchange: symbol.exchange,
      };

      axios
        .post("https://ccxt.alphaquark-case.com/websocket/subscribe", data)
        .then((response) => {
          // console.log(`Subscribed to ${symbol.tradingSymbol}:`, response.data);
        })
        .catch((error) => {
          // console.error(`Error subscribing to ${symbol.tradingSymbol}:`, error);
        });
    });
  };

  useEffect(() => {
    if (stockRecoNotExecuted && stockRecoNotExecuted.length > 0) {
      getCurrentPrice();
    }
  }, [stockRecoNotExecuted, userDetails]);

  const getLTPForSymbol = (symbol) => {
    const ltpOne = ltp.find((item) => {
      return item.tradingSymbol === symbol;
    })?.lastPrice;
    return ltpOne ? ltpOne.toFixed(2) : "-";
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    stockDetails.forEach((ele) => {
      if (ele.transactionType === "BUY") {
        const ltp = getLTPForSymbol(ele.tradingSymbol); // Get LTP for current symbol
        if (ltp !== "-") {
          totalAmount += parseFloat(ltp) * ele.quantity; // Calculate total amount for this trade
        }
      }
    });
    return totalAmount.toFixed(2); // Return total amount formatted to 2 decimal places
  };

  const [openZerodhaModel, setOpenZerodhaModel] = useState(false);
  const [selectedLength, setSelectedLength] = useState();
  const [singleStockSelectState, setSingleStockSelectState] = useState(false);
  const getCartAllStocks = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/cart/${userEmail}?trade_place_status=recommend`,
    };

    axios
      .request(config)
      .then((response) => {
        const transformedStockDetails = response?.data?.map((stock) => ({
          user_email: stock.user_email,
          trade_given_by: stock.trade_given_by,
          tradingSymbol: stock.Symbol,
          transactionType: stock.Type,
          exchange: stock.Exchange,
          segment: stock.Segment,
          productType: stock.ProductType,
          orderType: stock.OrderType,
          price: stock.Price,
          quantity: stock.Quantity,
          priority: stock.Priority,
          tradeId: stock.tradeId,
          user_broker: broker, // Assuming you want to set this from the existing context
        }));

        setStockDetails(transformedStockDetails);
        setSelectedLength(transformedStockDetails);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (userEmail) {
      getCartAllStocks();
    }
  }, [userEmail]);

  const handleSelectAllStocks = async () => {
    const newStockDetails = stockRecoNotExecuted.reduce((acc, stock) => {
      const isSelected = stockDetails.some(
        (selectedStock) =>
          selectedStock.tradingSymbol === stock.Symbol &&
          selectedStock.tradeId === stock.tradeId
      );

      if (!isSelected) {
        const ltp = getLTPForSymbol(stock.Symbol);
        const advisedRangeLower = stock.Advised_Range_Lower;
        const advisedRangeHigher = stock.Advised_Range_Higher;

        const shouldDisableTrade =
          (advisedRangeHigher === 0 && advisedRangeLower === 0) ||
          (advisedRangeHigher === null && advisedRangeLower === null) ||
          (advisedRangeHigher > 0 &&
            advisedRangeLower > 0 &&
            parseFloat(advisedRangeHigher) >= parseFloat(ltp) &&
            parseFloat(ltp) >= parseFloat(advisedRangeLower)) ||
          (advisedRangeHigher > 0 &&
            advisedRangeLower === 0 &&
            advisedRangeLower === null &&
            parseFloat(advisedRangeHigher) >= parseFloat(ltp)) ||
          (advisedRangeLower > 0 &&
            advisedRangeHigher === 0 &&
            advisedRangeHigher === null &&
            parseFloat(advisedRangeLower) <= parseFloat(ltp));

        if (shouldDisableTrade) {
          const newStock = {
            user_email: stock.user_email,
            trade_given_by: stock.trade_given_by,
            tradingSymbol: stock.Symbol,
            transactionType: stock.Type,
            exchange: stock.Exchange,
            segment: stock.Segment,
            productType: stock.ProductType,
            orderType: stock.OrderType,
            price: stock.Price,
            quantity: stock.Quantity,
            priority: stock.Priority,
            tradeId: stock.tradeId,
            user_broker: broker,
          };
          acc.push(newStock);
        }
      }

      return acc;
    }, []);

    if (newStockDetails.length > 0) {
      try {
        await axios.post(
          `${server.server.baseUrl}api/cart/add/add-multiple-to-cart`,
          {
            stocks: newStockDetails,
          }
        );
        // Optionally, update the state to reflect the changes in the UI
        getCartAllStocks();
      } catch (error) {
        console.error("Error adding stocks to cart", error);
      }
    }
  };
  const handleRemoveAllSelectedStocks = async () => {
    try {
      // Use all stock details in the cart for removal
      const stocksToRemove = [...stockDetails];

      if (stocksToRemove.length > 0) {
        await axios.post(
          `${server.server.baseUrl}api/cart/cart-items/remove/multiple/remove-multiple-from-cart`,
          {
            stocks: stocksToRemove,
          }
        );

        // Clear stockDetails since all stocks are removed
        setStockDetails([]);
        getCartAllStocks(); // Refresh the cart
      }
    } catch (error) {
      console.error("Error removing stocks from cart", error);
    }
  };

  const [authToken, setAuthToken] = useState(null);
  const location = useLocation();

  const [zerodhaStatus, setZerodhaStatus] = useState(null);
  // icici
  const [apiSession, setApiSession] = useState(null);
  // upstox code
  const [upstoxCode, setUpstoxCode] = useState(null);

  // const zerodha Login
  const [zerodhaRequestToken, setZerodhaRequestToken] = useState(null);
  const [zerodhaRequestType, setZerodhaRequestType] = useState(null);

  useEffect(() => {
    // Parse the query string
    const queryParams = new URLSearchParams(location.search);
    // Get the auth_token
    const token = queryParams.get("auth_token");
    // Set the auth token state
    setAuthToken(token);
    const zerodhaStatus = queryParams.get("status");
    setZerodhaStatus(zerodhaStatus);
    const icicApiSession = queryParams.get("apisession");
    setApiSession(icicApiSession);
    const getUpstoxCode = queryParams.get("code");
    setUpstoxCode(getUpstoxCode);
    const zerodhaRequestLoginToken = queryParams.get("request_token");
    setZerodhaRequestToken(zerodhaRequestLoginToken);
    const zerodhaLoginType = queryParams.get("type");
    setZerodhaRequestType(zerodhaLoginType);
  }, [location.search]);

  // zerodha start
  const handleZerodhaRedirect = async () => {
    localStorage.setItem(
      "stockDetailsZerodhaOrder",
      JSON.stringify(stockDetails)
    );
    const apiKey = "b0g1r806oitsamoe";

    const basket = stockDetails.map((stock) => {
      let baseOrder = {
        variety: "regular",
        tradingsymbol: stock.tradingSymbol,
        exchange: stock.exchange,
        transaction_type: stock.transactionType,
        order_type: stock.orderType,
        quantity: stock.quantity,
        readonly: false,
      };

      // Get the LTP for the current stock
      const ltp = getLTPForSymbol(stock.tradingSymbol);

      // If LTP is available and not '-', use it as the price
      if (ltp !== "-") {
        baseOrder.price = parseFloat(ltp);
      }

      // If it's a LIMIT order, use the LTP as the price
      if (stock.orderType === "LIMIT") {
        if (ltp !== "-") {
          baseOrder.price = parseFloat(ltp);
          baseOrder.variety = "regular";
        } else {
          baseOrder.variety = "regular";
          baseOrder.price = stock.limitPrice || 0; // Use limitPrice if available, or set to 0
        }
      }

      if (stock.quantity > 100) {
        baseOrder.readonly = true;
      }

      return baseOrder;
    });

    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://kite.zerodha.com/connect/basket";
    // form.target = "_blank";

    const apiKeyInput = document.createElement("input");
    apiKeyInput.type = "hidden";
    apiKeyInput.name = "api_key";
    apiKeyInput.value = apiKey;

    const dataInput = document.createElement("input");
    dataInput.type = "hidden";
    dataInput.name = "data";
    dataInput.value = JSON.stringify(basket);

    form.appendChild(apiKeyInput);
    form.appendChild(dataInput);
    document.body.appendChild(form);

    const currentISTDateTime = new Date();
    try {
      // Update the database with the current IST date-time
      await axios.put(`${server.server.baseUrl}api/zerodha/update-trade-reco`, {
        stockDetails: stockDetails,
        leaving_datetime: currentISTDateTime,
      });

      // Submit the form after the database is updated
      form.submit();
    } catch (error) {
      console.error("Failed to update trade recommendation:", error);
    }
  };

  const [zerodhaStockDetails, setZerodhaStockDetails] = useState(null);
  useEffect(() => {
    // Check for pending order data in localStorage
    const pendingOrderData = localStorage.getItem("stockDetailsZerodhaOrder");
    if (pendingOrderData) {
      setZerodhaStockDetails(JSON.parse(pendingOrderData));
    }
  }, []);

  const checkZerodhaStatus = () => {
    const currentISTDateTime = new Date();
    const istDatetime = moment(currentISTDateTime).format();
    if (zerodhaStatus !== null && zerodhaRequestType === "basket") {
      let data = JSON.stringify({
        apiKey: "b0g1r806oitsamoe",
        jwtToken: jwtToken,
        userEmail: userEmail,
        returnDateTime: istDatetime,
        trades: zerodhaStockDetails,
      });

      let config = {
        method: "post",
        url: `${server.server.baseUrl}api/zerodha/order-place`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          console.log("res", response);
          setOpenSucessModal(true);
          setOrderPlacementResponse(response.data.response);
          setOpenReviewTrade(false);
          getAllTrades();
          localStorage.removeItem("stockDetailsZerodhaOrder");
        })
        .catch((error) => {
          console.error(error);
          toast.error("Something went wrong. Please try again.", {
            duration: 5000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
    }
  };

  useEffect(() => {
    if (
      zerodhaStatus !== null &&
      zerodhaRequestType === "basket" &&
      jwtToken !== undefined
    ) {
      checkZerodhaStatus();
    }
  }, [zerodhaStatus, zerodhaRequestType, userEmail, jwtToken]);

  // icici direct start
  const [sessionToken, setSessionToken] = useState(null);
  const [iciciSuccessMsg, setIciciSuccessMsg] = useState(false);

  const hasConnectedIcici = useRef(false);
  const connectIciciDirect = () => {
    if (apiSession !== null && apiKey && !hasConnectedIcici.current) {
      let data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        sessionToken: apiSession,
      });

      let config = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}icici/customer-details`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response.data.Status === 200) {
            const session_token = response.data.Success.session_token;
            setSessionToken(session_token);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Something went wrong. Please try again.", {
            duration: 3000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
      hasConnectedIcici.current = true;
    }
  };

  useEffect(() => {
    if (apiSession) {
      connectIciciDirect();
    }
  }, [apiSession, userDetails]);

  // upstox start
  const [upstoxSessionToken, setUpstoxSessionToken] = useState(null);
  const hasConnectedUpstox = useRef(false);

  const connectUpstox = () => {
    if (
      upstoxCode !== null &&
      apiKey &&
      secretKey &&
      !hasConnectedUpstox.current
    ) {
      let data = JSON.stringify({
        apiKey: checkValidApiAnSecret(apiKey),
        apiSecret: checkValidApiAnSecret(secretKey),
        code: upstoxCode,
        redirectUri: "https://iamclearmind.alphaquark-case.com/stock-recommendation",
      });

      let config = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}upstox/gen-access-token`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response.data) {
            const session_token = response.data.access_token;
            setUpstoxSessionToken(session_token);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Something went wrong. Please try again.", {
            duration: 3000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
      hasConnectedUpstox.current = true;
    }
  };

  useEffect(() => {
    if (upstoxCode) {
      connectUpstox();
    }
  }, [upstoxCode, userDetails]);

  // zerodha start
  const [zerodhaAccessToken, setZerodhaAccessToken] = useState(null);
  const hasConnectedZerodha = useRef(false);
  const connectZerodha = () => {
    if (
      zerodhaRequestToken !== null &&
      zerodhaRequestType === "login" &&
      !hasConnectedZerodha.current
    ) {
      let data = JSON.stringify({
        apiKey: "b0g1r806oitsamoe",
        apiSecret: "u4lw9zhl3iqafay2s6salc800bs8pzjd",
        requestToken: zerodhaRequestToken,
      });

      let config = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}zerodha/gen-access-token`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response.data) {
            const session_token = response.data.access_token;
            setZerodhaAccessToken(session_token);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error("Something went wrong. Please try again.", {
            duration: 3000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
      hasConnectedZerodha.current = true;
    }
  };

  useEffect(() => {
    if (zerodhaRequestToken && zerodhaRequestType) {
      connectZerodha();
    }
  }, [zerodhaRequestToken, zerodhaRequestType, userDetails]);

  const isToastShown = useRef(false);

  const connectBrokerDbUpadte = () => {
    if (
      sessionToken ||
      upstoxSessionToken ||
      authToken ||
      (zerodhaAccessToken && zerodhaRequestType === "login")
    ) {
      if (!isToastShown.current) {
        isToastShown.current = true; // Prevent further execution
        let brokerData = {
          uid: userId,
          user_broker: sessionToken
            ? "ICICI Direct"
            : upstoxSessionToken
            ? "Upstox"
            : authToken
            ? "Angel One"
            : "Zerodha",
          jwtToken:
            sessionToken ||
            upstoxSessionToken ||
            zerodhaAccessToken ||
            authToken,
        };

        if (authToken) {
          brokerData = {
            ...brokerData,
            apiKey: "43Ecd6Ng",
          };
        }

        let config = {
          method: "put",
          url: `${server.server.baseUrl}api/user/connect-broker`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(brokerData),
        };

        axios
          .request(config)
          .then((response) => {
            setLoading(false);
            setIciciSuccessMsg(true);
            setOpenTokenExpireModel(false);
            setBrokerModel(false);
            toast.success(
              "You have been successfully logged in to your broker.",
              {
                duration: 3000,
                style: {
                  background: "white",
                  color: "#16a085",
                  maxWidth: "500px",
                  fontWeight: "bolder",
                  fontSize: "16px",
                  padding: "10px 20px",
                },
                iconTheme: {
                  primary: "#16a085",
                  secondary: "#FFFAEE",
                },
              }
            );
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            toast.error("Incorrect credentials. Please try again.", {
              duration: 5000,
              style: {
                background: "white",
                color: "#e43d3d",
                maxWidth: "500px",
                fontWeight: "bolder",
                fontSize: "14px",
                padding: "10px 20px",
              },
              iconTheme: {
                primary: "#e43d3d",
                secondary: "#FFFAEE",
              },
            });
          });
      }
    }
  };

  useEffect(() => {
    if (
      userId !== undefined &&
      (sessionToken ||
        upstoxSessionToken ||
        authToken ||
        (zerodhaAccessToken && zerodhaRequestType === "login"))
    ) {
      connectBrokerDbUpadte();
    }
  }, [userId, sessionToken, upstoxSessionToken, zerodhaAccessToken, authToken]);

  useEffect(() => {
    if (iciciSuccessMsg) {
      const toRef = setTimeout(() => {
        setIciciSuccessMsg(false);
        window.history.pushState({}, "", "/stock-recommendation");
        window.location.reload();
      }, 2000);
      return () => clearTimeout(toRef);
    }
  }, [iciciSuccessMsg]);

  useEffect(() => {
    const handleUrlChange = () => {
      if (location.pathname === "/stock-recommendation") {
        getUserDeatils();
      }
    };
    handleUrlChange();
  }, [location.pathname]);

  const [funds, setFunds] = useState({});

  const getAllFunds = () => {
    if (broker === "IIFL Securities") {
      if (clientCode) {
        let data = JSON.stringify({
          clientCode: clientCode,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}iifl/margin`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "ICICI Direct") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          sessionToken: jwtToken,
          secretKey: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}icici/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Upstox") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          accessToken: jwtToken,
          apiSecret: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}upstox/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Angel One") {
      if (apiKey && jwtToken) {
        let data = JSON.stringify({
          apiKey: apiKey,
          jwtToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Zerodha") {
      if (jwtToken) {
        let data = JSON.stringify({
          apiKey: "b0g1r806oitsamoe",
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}zerodha/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Kotak") {
      if (jwtToken) {
        let data = JSON.stringify({
          consumerKey: checkValidApiAnSecret(apiKey),
          consumerSecret: checkValidApiAnSecret(secretKey),
          accessToken: jwtToken,
          viewToken: viewToken,
          exchange: "NSE",
          segment: "CASH",
          product: "ALL",
          sid: sid,
          serverId: serverId,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}kotak/funds`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            // console.log("res", response);
            setFunds(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    getAllFunds();
  }, [userDetails]);

  const handleTrade = () => {
    getAllFunds();
    const isFundsEmpty =
      !funds || Object.keys(funds).length === 0 || !funds.availablecash;
    const isMarketHours = IsMarketHours();

    if (!isMarketHours) {
      toast.error("Orders cannot be placed after Market hours.", {
        duration: 3000,
        style: {
          background: "white",
          color: "#e43d3d",
          maxWidth: "500px",
          fontWeight: "bolder",
          fontSize: "14px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      return;
    }
    if (broker === "Zerodha") {
      if (todayDate > expireTokenDate || isFundsEmpty) {
        setOpenTokenExpireModel(true);
      } else {
        setOpenZerodhaModel(true);
      }
    } else {
      if (brokerStatus === null) {
        setBrokerModel(true);
      } else if (todayDate > expireTokenDate || isFundsEmpty) {
        setOpenTokenExpireModel(true);
      } else {
        setOpenReviewTrade(true);
      }
    }
  };

  const filteredAdvcideRangeStocks = stockRecoNotExecuted.filter((ele) => {
    const ltp = getLTPForSymbol(ele.Symbol);
    const advisedRangeLower = parseFloat(ele.Advised_Range_Lower);
    const advisedRangeHigher = parseFloat(ele.Advised_Range_Higher);

    return (
      (advisedRangeHigher === 0 && advisedRangeLower === 0) ||
      (advisedRangeHigher === null && advisedRangeLower === null) ||
      (advisedRangeHigher > 0 &&
        advisedRangeLower > 0 &&
        parseFloat(advisedRangeHigher) > parseFloat(ltp) &&
        parseFloat(ltp) > parseFloat(advisedRangeLower)) ||
      (advisedRangeHigher > 0 &&
        advisedRangeLower === 0 &&
        advisedRangeLower === null &&
        parseFloat(advisedRangeHigher) > parseFloat(ltp)) ||
      (advisedRangeLower > 0 &&
        advisedRangeHigher === 0 &&
        advisedRangeHigher === null &&
        parseFloat(advisedRangeLower) < parseFloat(ltp))
    );
  });

  const handleOpenReviewModal = () => {
    setOpenReviewTrade(false);
    setSingleStockSelectState(false);
    getCartAllStocks();
  };

  const handleOpenZerodhaModal = () => {
    setOpenZerodhaModel(false);
    setSingleStockSelectState(false);
    getCartAllStocks();
  };

  return (
    <div className="flex flex-col relative  w-full max-h-[calc(100vh-60px)] md:min-h-screen  bg-[#f9f9f9]">
      <Toaster position="top-center" reverseOrder={true} />
      {stockRecoNotExecuted.length !== 0 ? (
        <div className="flex flex-col  w-full min-h-screen  bg-[#f9f9f9] lg:pt-4">
          <div className="px-0 lg:px-[50px]  flex flex-row items-center lg:items-center  md:justify-between  h-[70px] md:h-[60px] font-poppins">
            <div className="flex flex-col md:my-4 px-2 md:px-0">
              <div className="px-4 text-[18px] lg:px-0 text-balck lg:text-[22px] leading-[30px] font-sans font-bold">
                Investment Advice(s)
              </div>
              {ignoredTrades && ignoredTrades.length > 0 ? (
                <div className=" px-4 lg:px-0 flex  md:items-center   ">
                  <div className="text-[#000000]/70 text-[12px] lg:text-[15px]  font-poppins l:leading-[22px] font-normal">
                    To view all ignored trades.
                    <Link
                      to={"/ignore-trades"}
                      className="ml-1 underline text-blue-500 text-[12px] lg:text-[15px] font-normal font-poppins cursor-pointer"
                    >
                      Click here
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="px-4 lg:px-0 text-sm text-[#000000]/70 leading-[20px] font-light font-poppins">
                  Investment advice to guide your next move.
                </div>
              )}
            </div>

            <div className="flex flex-row items-center space-x-3">
              {stockRecoNotExecuted.length !== 0 && (
                <>
                  {filteredAdvcideRangeStocks.length === stockDetails.length ? (
                    <div
                      onClick={handleRemoveAllSelectedStocks}
                      className=" text-xs md:text-lg py-2 hidden md:block md:px-6 text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                    >
                      <span className="">Deselect All</span>
                    </div>
                  ) : (
                    <div
                      onClick={handleSelectAllStocks}
                      className="text-xs md:text-lg py-2 hidden md:block md:px-6 text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
                    >
                      <span className="">Select All</span>
                    </div>
                  )}
                </>
              )}

              <button
                className="min-w-[90px] lg:w-[150px] hidden md:block disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-6 py-2 bg-black text-xs md:text-lg lg:px-4 lg:py-2 text-white font-medium rounded-md cursor-pointer"
                onClick={handleTrade}
                disabled={stockDetails?.length === 0}
              >
                <div className="flex flex-row justify-center items-center space-x-2">
                  {" "}
                  <TrendingUp className=" w-4 h-4 lg:h-5 lg:w-5" />
                  <span>Trade</span>
                  {singleStockSelectState === true ? (
                    <span className="font-medium mt-0.5 md:mt-1">
                      (
                      {(stockDetails?.length || 0) +
                        (selectedLength?.length || 0)}
                      )
                    </span>
                  ) : (
                    stockDetails?.length > 0 && (
                      <span className="font-medium mt-0.5 md:mt-1">
                        ({stockDetails?.length})
                      </span>
                    )
                  )}
                </div>
              </button>
            </div>
          </div>

          <div className="h-[calc(100vh-170px)] overflow-hidden  md:overflow-auto md:h-auto pb-[40px]  md:pb-0 w-full">
            {stockRecoNotExecuted.sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            ).length !== 0 ? (
              <div className="h-full overflow-scroll custom-scrollbar md:overflow-auto w-full grid gap-y-4 pb-4 px-2 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 md:gap-y-6 md:gap-x-3 xl:grid-cols-3 lg:gap-4 lg:pt-6 lg:px-[50px]  lg:pb-12">
                {stockRecoNotExecuted.map((ele, i) => {
                  const ltpData = ltp?.find(
                    (ltpItem) => ltpItem.tradingSymbol === ele.Symbol
                  );

                  return (
                    <div key={i}>
                      <NewStockCard
                        id={ele._id}
                        isSelected={stockDetails.some(
                          (stock) =>
                            stock.tradingSymbol === ele.Symbol &&
                            stock.tradeId === ele.tradeId
                        )}
                        symbol={ele.Symbol}
                        Price={ele.Price}
                        date={ele.date}
                        Quantity={ele.Quantity}
                        action={ele.Type}
                        orderType={ele.OrderType}
                        exchange={ele.Exchange}
                        segment={ele.Segment}
                        cmp={ele.CMP}
                        advisedRangeLower={ele.Advised_Range_Lower}
                        advisedRangeHigher={ele.Advised_Range_Higher}
                        tradeId={ele.tradeId}
                        rationale={
                          ele?.rationale ? ele.rationale : defaultRationale
                        }
                        recommendationStock={
                          stockRecoNotExecuted && stockRecoNotExecuted
                        }
                        setRecommendationStock={setStockRecoNotExecuted}
                        setStockDetails={setStockDetails}
                        stockDetails={stockDetails}
                        setOpenReviewTrade={setOpenReviewTrade}
                        setOpenIgnoreTradeModel={setOpenIgnoreTradeModel}
                        setStockIgnoreId={setStockIgnoreId}
                        ltp={ltpData ? ltpData?.lastPrice : undefined}
                        setOpenTokenExpireModel={setOpenTokenExpireModel}
                        todayDate={todayDate}
                        expireTokenDate={expireTokenDate}
                        brokerStatus={brokerStatus}
                        setBrokerModel={setBrokerModel}
                        getCurrentPrice={getCurrentPrice}
                        getUserDeatils={getUserDeatils}
                        setOpenZerodhaModel={setOpenZerodhaModel}
                        broker={broker}
                        funds={funds}
                        getCartAllStocks={getCartAllStocks}
                        setSingleStockSelectState={setSingleStockSelectState}
                        getAllFunds={getAllFunds}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center h-[calc(100vh-180px)]  lg:h-[calc(100vh-120px)]">
                <div className="text-xl md:text-3xl font-semibold font-sans ">
                  No Trade Recommendations
                </div>
                <div className="mt-1 text-sm md:text-lg font-normal font-poppins">
                  Advisor recommendations will appear here
                </div>
              </div>
            )}
          </div>

          {/* Bottom Fixed Buttom for Orders Not Placed  */}

          <div className="absolute bottom-0 bg-[#f9f9f9]   flex flex-row items-center justify-between  h-[65px] px-4 space-x-4 w-full md:hidden">
            {filteredAdvcideRangeStocks.length === stockDetails.length ? (
              <div
                onClick={handleRemoveAllSelectedStocks}
                className=" w-full flex items-center justify-center  text-[15px] py-3 px-2   text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
              >
                <span className="">Deselect All</span>
              </div>
            ) : (
              <div
                onClick={handleSelectAllStocks}
                className=" w-full flex items-center justify-center text-[15px] py-3 px-2   text-[#000000] font-medium font-poppins border-[1px] border-[#000000]/20 rounded-md cursor-pointer"
              >
                <span className="">Select All</span>
              </div>
            )}

            <button
              className="w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed px-2 py-3 bg-black text-[15px]  text-white font-medium rounded-md cursor-pointer"
              onClick={handleTrade}
              disabled={stockDetails?.length === 0}
            >
              <div className="flex flex-row justify-center items-center space-x-2">
                {" "}
                <TrendingUp className=" w-4 h-4 lg:h-5 lg:w-5" />
                <span>Trade</span>
                {singleStockSelectState === true ? (
                  <span className="font-medium mt-0.5 md:mt-1">
                    (
                    {(stockDetails?.length || 0) +
                      (selectedLength?.length || 0)}
                    )
                  </span>
                ) : (
                  stockDetails?.length > 0 && (
                    <span className="font-medium mt-0.5 md:mt-1">
                      ({stockDetails?.length})
                    </span>
                  )
                )}
              </div>
            </button>
          </div>

          {/* Bottom Fixed Buttom for Rejected Orders  */}

          {brokerModel === true ? (
            <ConnectBroker
              uid={userDetails && userDetails._id}
              userDetails={userDetails && userDetails}
              setBrokerModel={setBrokerModel}
              getUserDeatils={getUserDeatils}
              broker={broker}
              setBroker={setBroker}
              brokerModel={brokerModel}
            />
          ) : null}

          {updateUserDetails === true ? (
            <UpdateUserDeatils
              setUpdateUserDetails={setUpdateUserDetails}
              uid={userDetails && userDetails._id}
              userDetails={userDetails}
            />
          ) : null}

          {openTokenExpireModel === true ? (
            <TokenExpireBrokarModal
              openTokenExpireModel={openTokenExpireModel}
              setOpenTokenExpireModel={setOpenTokenExpireModel}
              userId={userId && userId}
              apiKey={apiKey}
              secretKey={secretKey}
              checkValidApiAnSecret={checkValidApiAnSecret}
              clientCode={clientCode}
              my2pin={my2pin}
              panNumber={panNumber}
              mobileNumber={mobileNumber}
              broker={broker}
              getUserDeatils={getUserDeatils}
            />
          ) : null}

          {/* Ignore Trade Model with Mobile Drawer */}

          {openIgnoreTradeModel === true ? (
            <IgnoreTradeModal
              openIgnoreTradeModel={openIgnoreTradeModel}
              setOpenIgnoreTradeModel={setOpenIgnoreTradeModel}
              handleIgnoredTrades={handleIgnoredTrades}
              stockIgnoreId={stockIgnoreId}
              ignoreText={ignoreText}
              setIgnoreText={setIgnoreText}
              ignoreLoading={ignoreLoading}
              style={style}
            />
          ) : null}

          {stockDetails.length !== 0 && openReviewTrade === true ? (
            <ReviewTradeModel
              calculateTotalAmount={calculateTotalAmount}
              getLTPForSymbol={getLTPForSymbol}
              stockDetails={stockDetails}
              setStockDetails={setStockDetails}
              setOpenReviewTrade={handleOpenReviewModal}
              placeOrder={placeOrder}
              loading={loading}
              funds={funds}
              openReviewTrade={openReviewTrade}
              getCartAllStocks={getCartAllStocks}
              broker={broker}
            />
          ) : stockDetails.length !== 0 &&
            broker === "Zerodha" &&
            openZerodhaModel === true ? (
            <ZerodhaReviewModal
              getLTPForSymbol={getLTPForSymbol}
              stockDetails={stockDetails}
              setStockDetails={setStockDetails}
              calculateTotalAmount={calculateTotalAmount}
              funds={funds}
              setOpenZerodhaModel={handleOpenZerodhaModal}
              handleZerodhaRedirect={handleZerodhaRedirect}
              openZerodhaModel={openZerodhaModel}
              getCartAllStocks={getCartAllStocks}
              broker={broker}
            />
          ) : null}

          {showStepGuideModal && (
            <StepGuideModal
              showStepGuideModal={showStepGuideModal}
              setShowStepGuideModal={setShowStepGuideModal}
            />
          )}
        </div>
      ) : (
        <>
          {isStepGuideLoading ? (
            <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
              <svg
                className="h-10 w-10 text-[#000000] animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : (
            <StepGuideScreen />
          )}
        </>
      )}

      {openSuccessModal && (
        <RecommendationSuccessModal
          setOpenSucessModal={setOpenSucessModal}
          orderPlacementResponse={orderPlacementResponse}
          setStockDetails={setStockDetails}
          openSuccessModal={openSuccessModal}
        />
      )}
    </div>
  );
};

export default StockRecommendation;
