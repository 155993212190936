import AdminDashboardLayout from "../AdminDashboardLayout";
import React, { useEffect, useState } from "react";
import fetchAdminData from "./AdminData";
import { Link } from "react-router-dom";
import { ChevronLeft, ChevronLeftIcon } from "lucide-react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";

const disclaimerData = [
  {
    heading: "General Disclaimer",
    text: "The information provided on this Website is for general informational purposes only. All information on the Website is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Website.",
  },
  {
    heading: "Not Professional Advice",
    text: "The Website does not contain professional advice. We encourage you to consult with the appropriate professionals before taking any actions based upon such information. Your use of or reliance on any information on the Website is solely at your own risk.",
  },
  {
    heading: "No Liability",
    text: "Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the Website and your reliance on any information on the Website is solely at your own risk.",
  },
  {
    heading: "External Links Disclaimer",
    text: "The Website may contain (or you may be sent through the Website) links to other websites or content belonging to or originating from third parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.",
  },
  {
    heading: "Errors and Omissions Disclaimer",
    text: "The information given by the Website is for general guidance on matters of interest only. Even if we take every precaution to ensure that the content of the Website is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules, and regulations, there may be delays, omissions, or inaccuracies in the information contained on the Website.",
  },
  {
    heading: "Fair Use Disclaimer",
    text: 'The Website may contain copyrighted material, the use of which has not always been specifically authorized by the copyright owner. We believe this constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the US Copyright Law.',
  },
  {
    heading: "Views Expressed Disclaimer",
    text: "The views and opinions expressed on the Website are those of the authors and do not necessarily reflect the official policy or position of any other agency, organization, employer, or company, including Clearmind.",
  },
];

const DisclaimerPage = () => {
  const [data, setData] = useState();
  const [user] = useAuthState(auth);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const newData = await fetchAdminData();
      // console.log("New",newData?.data)
      setData(newData?.data);
    };

    getData();
    setIsLoading(false);
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  console.log("data", data?.disclaimer);

  const disclaimerData = data?.disclaimer
    .split(/\n{1,}/)
    .map((item) => item.trim())
    .filter((item) => item.length > 0)
    .map((item) => ({ text: item }));

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="flex flex-col relative  w-full lg:min-h-screen  bg-[#f9f9f9] ">
          <div className="border-[1px] border-[#000000]/10 px-[20px]  sm:px-[30px] py-[14px] lg:px-20 lg:py-3 flex items-center justify-between">
            <h2 className=" font-sans text-[18px] sm:text-[24px] md:text-[28px] font-bold md:leading-[60px] text-left ">
              Disclaimer
            </h2>
            {!isLoading && user === null && (
              <Link
                to="/"
                className=" bg-[#000000] text-white text-[16px] md:text-[18px] font-medium font-poppins rounded-md px-6 py-[6px] md:px-8 md:py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
              >
                Login
              </Link>
            )}
          </div>

          <ul className="mt-1 space-y-4 list-decimal list-inside   px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
            {data &&
              disclaimerData?.map((item, index) => (
                <div key={index} className="font-semibold text-[#161515CC]">
                  <p className="font-poppins text-[16px] font-normal leading-[24px] text-left mt-2">
                    {item.text}
                  </p>
                </div>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default DisclaimerPage;
