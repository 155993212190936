import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import server from "../../utils/serverConfig";
import CryptoJS from "crypto-js";
import toast, { Toaster } from "react-hot-toast";
import { EyeIcon, EyeOffIcon, ArrowLeft, X, XIcon } from "lucide-react";
import IfflLogo from "../../assests/iifl_logo.png";
import AngelOneLogo from "../../assests/AngleLogo.png";
import Zerodha from "../../assests/Zerodha.png";
import Icici from "../../assests/icici.png";
import Upstox from "../../assests/upstox.svg";
import Kotak from "../../assests/kotak_securities.png";
import YouTube from "react-youtube";

import { useMediaQuery } from "../../hooks/use-media-query";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Drawer, DrawerContent } from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";
import styles from "../../Login/login.module.css";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full",
};

const ConnectBroker = ({
  uid,
  setBrokerModel,
  broker,
  setBroker,
  brokerModel,
}) => {
  const playerRef = useRef(null);
  const opts = {
    height: "300",
    width: "630",
    playerVars: {
      autoplay: 0,
    },
  };

  const handlePlayerReady = (event) => {
    // Pause the video when the player is ready
    event.target.pauseVideo();
  };

  const [clientCode, setClientCode] = useState("");
  const [loading, setLoading] = useState(false);

  const [qrValue, setQrValue] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [showApiKey, setShowApiKey] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const storeClientCode = () => {
    setClientCode(inputValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    // Prevent further calls

    let data = JSON.stringify({
      uid: uid,
      user_broker: broker,
      clientCode: clientCode,
      qrValue: qrValue,
      apiKey: apiKey,
      password: password,
    });

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/user/connect-broker`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setIsLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);

        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        toast.success("Your broker has been successfully Connected.", {
          duration: 4000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setBrokerModel(false); // Close the modal after 3 seconds
        setIsLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  const checkValidApiAnSecret = (details) => {
    const bytesKey = CryptoJS.AES.encrypt(details, "ApiKeySecret");
    const Key = bytesKey.toString();
    if (Key) {
      return Key;
    }
  };

  const [secretKey, setSecretKey] = useState("");
  const [showSecretKey, setShowSecretKey] = useState(false);

  const updateSecretKey = () => {
    setLoading(true);
    if (broker === "Upstox") {
      let data = JSON.stringify({
        uid: uid,
        apiKey: checkValidApiAnSecret(apiKey),
        secretKey: checkValidApiAnSecret(secretKey),
        redirect_uri: "https://iamclearmind.alphaquark-case.com/stock-recommendation",
      });
      let config = {
        method: "post",
        url: `${server.server.baseUrl}api/upstox/update-key`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          if (response) {
            window.location.href = response.data.response;
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          toast.error("Incorrect credential.Please try again", {
            duration: 5000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
    } else {
      let data = JSON.stringify({
        uid: uid,
        user_broker: broker,
        apiKey: checkValidApiAnSecret(apiKey),
        secretKey: checkValidApiAnSecret(secretKey),
      });
      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/icici/update-key`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          window.location.href = `https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(
            apiKey
          )}`;
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const isDesktop = useMediaQuery("(min-width: 830px)");

  const [selectMethod, setSelectMethod] = useState("Mobile");
  const [mobileNumber, setMobileNumber] = useState("");
  const [panCard, setPanCard] = useState("");
  const [mpin, setMpin] = useState("");
  const [showMpin, setShowMpin] = useState(false);

  const [openOtpBox, setOpenOtpBox] = useState(false);
  const [storeResponse, setStoreResponse] = useState();
  const [otp, setOtp] = useState("");
  const updateKotakSecretKey = () => {
    setLoading(true);

    let data = {
      uid: uid,
      apiKey: checkValidApiAnSecret(apiKey),
      secretKey: checkValidApiAnSecret(secretKey),
    };

    // Conditionally add optional fields
    if (password) {
      data.password = password;
    }

    if (clientCode) {
      data.userid = clientCode;
    }

    if (mobileNumber) {
      data.mobileNumber = mobileNumber;
    }

    if (panCard) {
      data.pan = panCard;
    }

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/kotak/update-key`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setStoreResponse(response.data.response);
        setOpenOtpBox(true);
        // Handle success response
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  const submitOtp = () => {
    setLoading(true);

    let data = {
      uid: uid,
      apiKey: checkValidApiAnSecret(apiKey),
      secretKey: checkValidApiAnSecret(secretKey),
      jwtToken: storeResponse.access_token,
      password: password,
      otp: otp,
      sid: storeResponse.sid,
      viewToken: storeResponse.view_token,
    };

    // Conditionally add optional fields (pan, mobile, userId)
    if (panCard) {
      data.pan = panCard;
    } else if (mobileNumber) {
      data.mobileNumber = mobileNumber;
    }

    // Conditionally add optional fields

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/kotak/connect-broker`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        toast.success("You have been successfully logged in to your broker.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setBrokerModel(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  if (isDesktop) {
    return (
      <Dialog open={brokerModel} onOpenChange={setBrokerModel}>
        <DialogContent className="w-auto flex justify-center animate-modal">
          <Toaster position="top-center" reverseOrder={true} />
          {!broker && !clientCode ? (
            <div className="relative bg-[#ffffff] max-w-[450px]  rounded-[10px]  py-6 ">
              <div className="flex flex-col space-y-3 px-6 md:px-10  space-x-4 items-center justify-center text-black text-center text-2xl font-bold mt-4 mb-4">
                <span className="text-black text-center text-2xl md:text-3xl font-medium  font-poppins">
                  Select Your Broker
                </span>
                <span className="text-[#000000]/60 font-poppins text-[15px] text-center leading-[20px]  font-medium md:px-6">
                  To view recommendations from Advisor Connect Broker.
                </span>
              </div>
              <div className="md:flex flex-col space-y-5  px-6 mt-8 md:px-10 ">
                <div
                  onClick={() => setBroker("IIFL Securities")}
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={IfflLogo}
                    alt="IIFL Logo"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with IIFL
                  </span>
                </div>

                <a
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20   py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                  href="https://smartapi.angelbroking.com/publisher-login?api_key=43Ecd6Ng"
                >
                  <img
                    src={AngelOneLogo}
                    alt="AngelOne Logo"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with AngelOne
                  </span>
                </a>
                <div
                  onClick={() =>
                    (window.location.href =
                      "https://kite.zerodha.com/connect/login?api_key=b0g1r806oitsamoe&v=3")
                  }
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={Zerodha}
                    alt="Zerodha"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with Zerodha
                  </span>
                </div>
                <div
                  onClick={() => setBroker("ICICI Direct")}
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={Icici}
                    alt="Zerodha"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with ICICI Direct
                  </span>
                </div>
                <div
                  onClick={() => setBroker("Upstox")}
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={Upstox}
                    alt="Upstox Logo"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with Upstox
                  </span>
                </div>
                <div
                  onClick={() => setBroker("Kotak")}
                  className="group flex space-x-4 items-center justify-center text-black text-center text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={Kotak}
                    alt="Kotak Logo"
                    width={40}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-[18px] font-medium  font-poppins">
                    Continue with Kotak
                  </span>
                </div>
              </div>
            </div>
          ) : broker === "ICICI Direct" || broker === "Upstox" ? (
            <div className="relative bg-[#ffffff] w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setBroker("");
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex ">Back</div>
              </div>
              {broker === "Upstox" ? (
                <div className="mt-6 px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                  <div className="mb-8 rounded-xl ">
                    <YouTube
                      videoId={`yfTXrjl0k3E`}
                      className={`videoIframe rounded-xl `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>
                  <div
                    className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for Upstox :
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>1.</div>{" "}
                    <div className="ml-2">
                      {" "}
                      Visit
                      <a
                        href="https://shorturl.at/plWYJ"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 text-[#0000FF]"
                      >
                        https://shorturl.at/plWYJ
                      </a>
                      and log in using your phone number. Verify your identity
                      with the OTP and continue.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>2.</div>{" "}
                    <div className="ml-2">
                      Enter your 6-digit PIN and continue.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>3.</div>{" "}
                    <div className="ml-2">
                      Click on the "New App" button. Fill in the "App Name"
                      field with "Clearmind" or a name of your choice. Enter
                      the "Redirect URL" as
                      <span className="px-2 text-[#0000FF]">
                        https://iamclearmind.alphaquark-case.com/stock-recommendation
                      </span>
                      You can skip the Postback URL and Description as they are
                      optional. Accept the Terms & Conditions and click on the
                      "Continue" button. Please ensure that the "Redirect URL"
                      is entered correctly as mentioned above.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>4.</div>{" "}
                    <div className="ml-2">
                      Review the details (make sure you don't have more than 2
                      apps) and click on the "Confirm Plan" button. Your API is
                      now ready! Click on the "Done" button.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>5.</div>{" "}
                    <div className="ml-2">
                      Click on the newly created app, copy your API and Secret
                      Key, and enter these details on the designated screen.
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-6 px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                  <div className="mb-8 rounded-xl ">
                    <YouTube
                      videoId={`XFLjL8hOctI`}
                      className={`videoIframe rounded-xl `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>

                  <div
                    className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for ICICI:
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>1.</div>{" "}
                    <div className="ml-2">
                      {" "}
                      Visit
                      <a
                        href="https://api.icicidirect.com/apiuser/home"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 text-[#0000FF]"
                      >
                        api.icicidirect.com/apiuser/home
                      </a>
                      and log in using your username and password. Verify your
                      identity with the OTP and submit.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>2.</div>{" "}
                    <div className="ml-2">
                      Click on the "Register an App" tab, then fill in the "App
                      Name" field with "Clearmind" or a name of your choice.
                      Enter the "Redirect URL" as
                      <span className="px-2 text-[#0000FF]">
                        ${server.ccxtServer.baseUrl}icici/auth-callback/iamclearmind
                      </span>
                      and click "Submit". Please ensure that "redirect URL"
                      entered correctly as mentioned above.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>3.</div>{" "}
                    <div className="ml-2">
                      Navigate to the "View Apps" tab and copy your API and
                      Secret Key- enter these details in the screen.
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-10 md:flex flex-col space-y-4 px-12 ">
                <div className="text-3xl font-bold">Connect {broker}</div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    Api Key :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      placeholder="Api Key"
                      type={showApiKey ? "text" : "password"}
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                      className={`${style.inputBox}`}
                    />
                    {showApiKey ? (
                      <EyeIcon
                        onClick={() => setShowApiKey(false)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() => setShowApiKey(true)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    )}
                  </div>
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    Secret Key :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      placeholder="Secret Key"
                      type={showSecretKey ? "text" : "password"}
                      value={secretKey}
                      onChange={(e) => setSecretKey(e.target.value)}
                      className={`${style.inputBox}`}
                    />
                    {showSecretKey ? (
                      <EyeIcon
                        onClick={() => setShowSecretKey(false)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() => setShowSecretKey(true)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!apiKey || !secretKey}
                    onClick={updateSecretKey}
                  >
                    {loading === true ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : broker === "Kotak" ? (
            <div className="relative bg-[#ffffff] w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setBroker("");
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex ">Back</div>
              </div>

              <div className="mt-6 px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div className="mb-8 rounded-xl ">
                  <YouTube
                    videoId={`XFLjL8hOctI`}
                    className={`videoIframe rounded-xl `}
                    opts={opts}
                    onReady={handlePlayerReady}
                    ref={playerRef}
                  />
                </div>

                <div
                  className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                >
                  Steps to Obtain API and Secret Key for Kotak:
                </div>
              </div>
              {openOtpBox === true ? (
                <div className="mt-10 md:flex flex-col space-y-4 px-12 ">
                  <div className="text-3xl font-bold">Connect {broker}</div>

                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                      Otp :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Secret Key"
                        type="number"
                        value={mpin}
                        onChange={(e) => setMpin(e.target.value)}
                        className={`${style.inputBox}`}
                      />
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                      Mpin :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Secret Key"
                        type="number"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className={`${style.inputBox}`}
                      />
                    </div>
                  </div>

                  <div className="">
                    <button
                      className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                      disabled={!mpin || !otp}
                      onClick={submitOtp}
                    >
                      {loading === true ? (
                        <span className="flex flex-row justify-center ">
                          <svg
                            className="h-7 w-7 text-[#ffffff] animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        `Submit Otp`
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-10 md:flex flex-col space-y-4 px-12 ">
                  <div className="text-3xl font-bold">Connect {broker}</div>
                  <div className="text-base text-rose">
                    We will not store your login credentials, you will need to
                    enter it while accepting trades
                  </div>
                  <div className="w-[90%] flex flex-row justify-between items-center border-2 border-[#000000]/40 rounded-md">
                    <div
                      className={
                        selectMethod === "Mobile"
                          ? "w-1/2 text-center px-8 py-3 bg-black text-white font-bold text-lg cursor-pointer rounded-l-md"
                          : "w-1/2 text-center px-8 py-3 bg-[#000000]/40  text-white text-lg  cursor-pointer rounded-l-md"
                      }
                      onClick={() => setSelectMethod("Mobile")}
                    >
                      Mobile
                    </div>
                    <div
                      className={
                        selectMethod === "PAN"
                          ? "w-1/2 text-center px-8 py-3 bg-black text-white font-bold text-lg  cursor-pointer"
                          : "w-1/2 text-center px-8 py-3 bg-[#000000]/40  text-white text-lg  cursor-pointer"
                      }
                      onClick={() => setSelectMethod("PAN")}
                    >
                      PAN
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                      Consumer Key :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Api Key"
                        type={showApiKey ? "text" : "password"}
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        className={`${style.inputBox}`}
                      />
                      {showApiKey ? (
                        <EyeIcon
                          onClick={() => setShowApiKey(false)}
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() => setShowApiKey(true)}
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                      Consumer Secret :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Secret Key"
                        type={showSecretKey ? "text" : "password"}
                        value={secretKey}
                        onChange={(e) => setSecretKey(e.target.value)}
                        className={`${style.inputBox}`}
                      />
                      {showSecretKey ? (
                        <EyeIcon
                          onClick={() => setShowSecretKey(false)}
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() => setShowSecretKey(true)}
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      )}
                    </div>
                  </div>

                  {selectMethod === "Mobile" ? (
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                        Mobile Number :{" "}
                      </div>
                      <div className={`relative ${style.inputStartDiv} `}>
                        <input
                          placeholder="Mobile Number"
                          type={mobileNumber ? "number" : "password"}
                          value={mobileNumber}
                          onChange={(e) => setMobileNumber(e.target.value)}
                          className={`${style.inputBox}`}
                        />
                      </div>
                    </div>
                  ) : selectMethod === "PAN" ? (
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                        Pan Card :{" "}
                      </div>
                      <div className={`relative ${style.inputStartDiv} `}>
                        <input
                          placeholder="Pan Card"
                          type={"text"}
                          value={panCard}
                          onChange={(e) => setPanCard(e.target.value)}
                          className={`${style.inputBox}`}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                        User Id :{" "}
                      </div>
                      <div className={`relative ${style.inputStartDiv} `}>
                        <input
                          placeholder="User Id"
                          type={"text"}
                          value={clientCode}
                          onChange={(e) => setClientCode(e.target.value)}
                          className={`${style.inputBox}`}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                      Password :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={`${style.inputBox}`}
                      />
                      {showSecretKey ? (
                        <EyeIcon
                          onClick={() => setShowPassword(false)}
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() => setShowPassword(true)}
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      )}
                    </div>
                  </div>

                  <div className="">
                    <button
                      className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                      disabled={!apiKey || !secretKey}
                      onClick={updateKotakSecretKey}
                    >
                      {loading === true ? (
                        <span className="flex flex-row justify-center ">
                          <svg
                            className="h-7 w-7 text-[#ffffff] animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        `Connect ${broker}`
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : broker && !clientCode ? (
            <div className="relative bg-[#ffffff] w-full md:min-w-[90vw] h-[560px] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setBroker("");
                  setClientCode("");
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex ">Back</div>
              </div>

              <div className="mt-4 px-[28px]  md:h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                >
                  Step-by-Step Guide:
                </div>
                <div className="text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[36px] text-black font-normal font-poppins ">
                  1. First enter your unique client code in the designated
                  field.
                </div>
              </div>

              <div className="mt-10 md:flex flex-col space-y-3 px-6 lg:px-12 ">
                <div className="text-[20px] font-semibold font-poppins">
                  Connect {broker}
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-[16px] font-semibold font-poppins">
                    Client Code :{" "}
                  </div>
                  <div className={style.inputStartDiv}>
                    <input
                      id="clientCode"
                      type="text"
                      placeholder="Client Code"
                      className={`${style.inputBox}`}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    onClick={storeClientCode}
                    disabled={!inputValue}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          ) : broker && clientCode ? (
            <div className="relative bg-[#ffffff] w-full md:min-w-[90vw] h-[560px] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setClientCode("");
                  setApiKey("");
                  setQrValue("");
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex ">Back</div>
              </div>

              <div className="mt-6 px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                >
                  Step-by-Step Guide:
                </div>
                <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                  <div>&#x2022;</div>{" "}
                  <div className="ml-2">
                    {" "}
                    In the next field, enter your My2PIN, which is your date of
                    birth. Ensure you use the correct format as specified (e.g.,
                    YYYYMMDD).
                  </div>
                </div>
                <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                  <div>&#x2022;</div>{" "}
                  <div className="ml-2">
                    Type in your account password. Remember, this password is
                    only used for verification purposes and is not stored in the
                    backend, hence you will be prompted for password again once
                    the password token validity expires.
                  </div>
                </div>
              </div>

              <div className="mt-10 md:flex flex-col space-y-4 px-12 ">
                <div className="text-3xl font-bold">Connect {broker}</div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    My2Pin :{" "}
                  </div>
                  <div className={style.inputStartDiv}>
                    <input
                      type="number"
                      placeholder="Enter your my2pin"
                      className={`${style.inputBox}`}
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    Password :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      id="pass"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={`${style.inputBox}`}
                    />
                    {showPassword ? (
                      <EyeIcon
                        onClick={() => setShowPassword(false)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() => setShowPassword(true)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!apiKey || !password}
                    onClick={handleSubmit}
                  >
                    {loading === true ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={brokerModel} onOpenChange={setBrokerModel}>
      <DrawerContent className="focus:outline-none max-h-[560px] animate-modal">
        <div
          onClick={() => setBrokerModel(false)}
          className="absolute top-4 right-3 flex items-center justify-center "
        >
          <XIcon className="h-5 w-5 text-gray-900" />
        </div>

        <div className="px-6 py-2 h-full flex flex-col overflow-y-auto">
          <Toaster position="top-center" reverseOrder={true} />
          {!broker && !clientCode ? (
            <div className="relative bg-[#ffffff] w-full h-[440px]  rounded-[10px]">
              <div className="flex flex-col space-y-3 px-6 md:px-10  space-x-4 items-center justify-center text-black text-center text-2xl font-bold mt-4 mb-4">
                <span className="text-black text-center text-2xl md:text-3xl font-medium  font-poppins">
                  Select Your Broker
                </span>
                <span className="text-[#000000]/60 font-poppins text-[15px] text-center leading-[20px]  font-medium md:px-6">
                  To view recommendations from Advisor Connect Broker.
                </span>
              </div>
              <div className="md:flex flex-col space-y-3 px-2 md:px-10 ">
                <div
                  onClick={() => setBroker("IIFL Securities")}
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={IfflLogo}
                    alt="IIFL Logo"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with IIFL
                  </span>
                </div>

                <a
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20   py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                  href="https://smartapi.angelbroking.com/publisher-login?api_key=43Ecd6Ng"
                >
                  <img
                    src={AngelOneLogo}
                    alt="AngelOne Logo"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with AngelOne
                  </span>
                </a>
                <div
                  onClick={() =>
                    (window.location.href =
                      "https://kite.zerodha.com/connect/login?api_key=b0g1r806oitsamoe&v=3")
                  }
                  className="group flex space-x-4 items-center justify-center text-black text-center  text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={Zerodha}
                    alt="Zerodha"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with Zerodha
                  </span>
                </div>
                <div
                  onClick={() => setBroker("ICICI Direct")}
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={Icici}
                    alt="Zerodha"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with ICICI Direct
                  </span>
                </div>
                <div
                  onClick={() => setBroker("Upstox")}
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={Upstox}
                    alt="Upstox Logo"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with Upstox
                  </span>
                </div>
                <div
                  onClick={() => setBroker("Kotak")}
                  className="group flex space-x-4 items-center justify-center text-black text-center text-base sm:text-2xl font-bold border-[1px] border-[#000000]/20  py-2 rounded-[6px] hover:bg-black  transition-all duration-150 ease-linear cursor-pointer"
                >
                  <img
                    src={Kotak}
                    alt="Kotak Logo"
                    width={30}
                    className="rounded-2xl"
                  />
                  <span className="text-black group-hover:text-white text-center text-sm sm:text-[18px] font-medium  font-poppins">
                    Continue with Kotak
                  </span>
                </div>
              </div>
            </div>
          ) : broker === "ICICI Direct" || broker === "Upstox" ? (
            <div className="h-[90%] md:h-[560px] pt-6 pb-2 relative bg-[#ffffff] w-full md:w-[90%] grid grid-cols-1 md:grid-cols-2 rounded-[10px] overflow-y-auto">
              <div
                className="flex flex-row items-center space-x-2   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setBroker("");
                }}
              >
                <div>
                  <ArrowLeft size={20} />
                </div>
                <div className="text-[12px] flex md:text-[16px] ">Back</div>
              </div>
              {broker !== "Upstox" ? (
                <div className="h-full pt-4 ">
                  <div className="mb-8 rounded-xl ">
                    <YouTube
                      videoId={`yfTXrjl0k3E`}
                      className={`videoIframe rounded-xl `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>
                  <div
                    className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for Upstox :
                  </div>
                  <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>1.</div>
                    <div className="ml-3">
                      {" "}
                      Visit
                      <a
                        href="https://shorturl.at/plWYJ"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 text-[#0000FF]"
                      >
                        https://shorturl.at/plWYJ
                      </a>
                      and log in using your phone number. Verify your identity
                      with the OTP and continue.
                    </div>
                  </div>
                  <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>2.</div>
                    <div className="ml-2">
                      Enter your 6-digit PIN and continue.
                    </div>
                  </div>
                  <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>3.</div>{" "}
                    <div className="ml-2">
                      Click on the "New App" button. Fill in the "App Name"
                      field with "Clearmind" or a name of your choice. Enter
                      the "Redirect URL" as
                      <span className="px-2 text-[#0000FF]">
                        https://iamclearmind.alphaquark-case.com/stock-recommendation
                      </span>
                      You can skip the Postback URL and Description as they are
                      optional. Accept the Terms & Conditions and click on the
                      "Continue" button. Please ensure that the "Redirect URL"
                      is entered correctly as mentioned above.
                    </div>
                  </div>
                  <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>4.</div>{" "}
                    <div className="ml-2">
                      Review the details (make sure you don't have more than 2
                      apps) and click on the "Confirm Plan" button. Your API is
                      now ready! Click on the "Done" button.
                    </div>
                  </div>
                  <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>5.</div>{" "}
                    <div className="ml-2">
                      Click on the newly created app, copy your API and Secret
                      Key, and enter these details on the designated screen.
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" h-full pt-4">
                  <div className="mb-8 rounded-xl  ">
                    <YouTube
                      videoId={`XFLjL8hOctI`}
                      className={`videoIframe rounded-xl  `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>

                  <div
                    className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for ICICI:
                  </div>
                  <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>1.</div>
                    <div className="ml-3">
                      Visit
                      <a
                        href="https://api.icicidirect.com/apiuser/home"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 text-[#0000FF]"
                      >
                        api.icicidirect.com/apiuser/home
                      </a>
                      and log in using your username and password. Verify your
                      identity with the OTP and submit.
                    </div>
                  </div>
                  <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>2.</div>{" "}
                    <div className="ml-2">
                      Click on the "Register an App" tab, then fill in the "App
                      Name" field with "Clearmind" or a name of your choice.
                      Enter the "Redirect URL" as
                      <span className="px-2 text-[#0000FF]">
                        ${server.ccxtServer.baseUrl}icici/auth-callback/iamclearmind
                      </span>
                      and click "Submit". Please ensure that "redirect URL"
                      entered correctly as mentioned above.
                    </div>
                  </div>
                  <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>3.</div>{" "}
                    <div className="ml-2">
                      Navigate to the "View Apps" tab and copy your API and
                      Secret Key- enter these details in the screen.
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-6 md:flex flex-col space-y-4 px-2 ">
                <div className="text-2xl font-bold">Connect {broker}</div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    Api Key :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      placeholder="Api Key"
                      type={showApiKey ? "text" : "password"}
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                      className={`${style.inputBox}`}
                    />
                    {showApiKey ? (
                      <EyeIcon
                        onClick={() => setShowApiKey(false)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() => setShowApiKey(true)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    )}
                  </div>
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    Secret Key :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      placeholder="Secret Key"
                      type={showSecretKey ? "text" : "password"}
                      value={secretKey}
                      onChange={(e) => setSecretKey(e.target.value)}
                      className={`${style.inputBox}`}
                    />
                    {showSecretKey ? (
                      <EyeIcon
                        onClick={() => setShowSecretKey(false)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() => setShowSecretKey(true)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-sm sm:text-[18px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!apiKey || !secretKey}
                    onClick={updateSecretKey}
                  >
                    {loading === true ? (
                      <LoadingSpinner />
                    ) : (
                      `Connect ${broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : broker && !clientCode ? (
            <div className=" bg-[#ffffff] w-full overflow-auto grid grid-cols-1 pt-2 pb-2  lg:grid-cols-2 rounded-[10px]  lg:py-8">
              <div
                className="flex flex-row items-center space-x-2  text-black/80 absolute  left-4 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setBroker("");
                  setClientCode("");
                }}
              >
                <div>
                  <ArrowLeft size={20} />
                </div>
                <div className="text-[12px] flex md:text-[16px] ">Back</div>
              </div>

              <div className="mt-4 px-2 md:h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                >
                  Step-by-Step Guide:
                </div>
                <div className="text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[36px] text-black font-normal font-poppins ">
                  1. First enter your unique client code in the designated
                  field.
                </div>
              </div>

              <div className="mt-10 px-2 md:flex flex-col space-y-3 ">
                <div className="text-[20px] font-semibold font-poppins">
                  Connect {broker}
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-[16px] font-semibold font-poppins">
                    Client Code :{" "}
                  </div>
                  <div className={style.inputStartDiv}>
                    <input
                      id="clientCode"
                      type="text"
                      placeholder="Client Code"
                      className={`${style.inputBox}`}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-sm sm:text-[18px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    onClick={storeClientCode}
                    disabled={!inputValue}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          ) : broker && clientCode ? (
            <div className="h-[560px] pt-8 pb-2 relative bg-[#ffffff] w-full  grid grid-cols-1 md:grid-cols-2 rounded-[10px]  overflow-y-auto">
              <div
                className="flex flex-row items-center space-x-2   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setClientCode("");
                  setApiKey("");
                  setQrValue("");
                }}
              >
                <div>
                  <ArrowLeft size={20} />
                </div>
                <div className="text-[12px] flex md:text-[16px] ">Back</div>
              </div>

              <div className="px-2 h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                >
                  Step-by-Step Guide:
                </div>
                <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                  <div>&#x2022;</div>{" "}
                  <div className="ml-2">
                    {" "}
                    In the next field, enter your My2PIN, which is your date of
                    birth. Ensure you use the correct format as specified (e.g.,
                    YYYYMMDD).
                  </div>
                </div>
                <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                  <div>&#x2022;</div>{" "}
                  <div className="ml-2">
                    Type in your account password. Remember, this password is
                    only used for verification purposes and is not stored in the
                    backend, hence you will be prompted for password again once
                    the password token validity expires.
                  </div>
                </div>
              </div>

              <div className="mt-6 md:flex flex-col space-y-4 px-2">
                <div className="text-2xl font-bold">Connect {broker}</div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    My2Pin :{" "}
                  </div>
                  <div className={style.inputStartDiv}>
                    <input
                      type="number"
                      placeholder="Enter your my2pin"
                      className={`${style.inputBox}`}
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    Password :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      id="pass"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={`${style.inputBox}`}
                    />
                    {showPassword ? (
                      <EyeIcon
                        onClick={() => setShowPassword(false)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() => setShowPassword(true)}
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-sm sm:text-[18px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!apiKey || !password}
                    onClick={handleSubmit}
                  >
                    {loading === true ? (
                      <LoadingSpinner />
                    ) : (
                      `Connect ${broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default ConnectBroker;
