import React, { useState, useEffect } from "react";

import Logo from "../../assests/Logo.jpg";
import { auth, provider } from "../../firebase";
import axios from "axios";
import { FcGoogle } from "react-icons/fc";
import toast, { Toaster } from "react-hot-toast";
import server from "../../utils/serverConfig";
import { saveTokenInLocalStorage } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginConfirmedAction } from "../../store/actions/AuthActions";
import styles from "../../Login/login.module.css";
import AdminSignInEmail from "./adminSignInEmail";
import AdminSignUpEmail from "./adminSignUpEmail";
import ForgotPassword from "../../Login/forgotPassword";
const SignIn = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signIn = async () => {
    try {
      const response = await auth.signInWithPopup(provider);

      const userDetails = response.additionalUserInfo.profile;
      const token = response.credential?.idToken; // Ensure token is available

      if (token) {
        // Call the login API with email
        await axios.post(`${server.server.baseUrl}api/admin/allowed/login`, {
          email: userDetails.email,
        });

        // Save token and user details in localStorage
        saveTokenInLocalStorage(userDetails, token);

        // Dispatch login action
        dispatch(loginConfirmedAction({ ...userDetails, token }));

        // Navigate to admin dashboard
        navigate("/admin");
      } else {
        throw new Error("No token received");
      }
    } catch (err) {
      console.error(err);
      toast.error(
        `Login is restricted. For advisor onboarding, contact pratik@Clearmind.in.
        `,
        {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "560px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
  };

  const [showforgotPassword, setShowForgotPassord] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  return (
    <div
      className={`flex min-h-screen  justify-center items-center bg-gray-100`}
    >
      <Toaster position="top-center" reverseOrder={true} />
      <div
        className={`bg-white w-[500px] flex flex-col   justify-between border-[1px] border-[#000000]/20 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-[10px] px-[10px]  lg:px-[50px] lg:py-[30px]`}
      >
        {showSignUp === true ? (
          <div className="px-2 py-4 flex flex-col  space-y-8">
            <div className="flex flex-col justify-center items-center space-y-4">
              <img
                src={Logo}
                alt="Clearmind Logo"
                width={80}
                className="rounded-full"
              />
              <span className="text-black text-xl lg:text-2xl font-bold font-sans">
                Clearmind Dashboard
              </span>
            </div>

            <div className="flex flex-col  space-y-8">
              <AdminSignUpEmail
                showSignUp={showSignUp}
                setShowSignUp={setShowSignUp}
              />
            </div>
          </div>
        ) : showforgotPassword === true ? (
          <div className="px-2 py-4 flex flex-col  space-y-4">
            <div className="flex flex-col justify-center items-center space-y-2">
              <img
                src={Logo}
                alt="Clearmind Logo"
                width={80}
                className="rounded-full"
              />
              <span className="text-black text-xl lg:text-2xl font-bold font-sans">
                Clearmind Dashboard
              </span>
            </div>

            <div className="flex flex-col  space-y-8">
              <ForgotPassword
                setShowForgotPassord={setShowForgotPassord}
                showforgotPassword={showforgotPassword}
              />
            </div>
          </div>
        ) : (
          <div className="px-2 py-4 flex flex-col  space-y-4">
            <div className="flex flex-col justify-center items-center space-y-2">
              <img
                src={Logo}
                alt="Clearmind Logo"
                width={80}
                className="rounded-full"
              />
              <span className="text-black text-xl lg:text-2xl font-bold font-sans">
                Clearmind
              </span>
            </div>

            <div
              className={`text-center text-black text-2xl lg:text-[36px] font-semibold font-poppins my-8  `}
            >
              Dashboard Login
            </div>

            <div
              className="flex items-center justify-center space-x-3 py-2 bg-black  text-center rounded-lg cursor-pointer"
              onClick={signIn}
            >
              <FcGoogle className=" w-[28px] h-[28px]" />
              <span className="text-white font-medium text-lg font-poppins">
                Sign In with Google
              </span>
            </div>
            <div className={styles.centerseparatorMobile}>
              <div className="flex items-center text-[#000000]/40 font-poppins text-[18px] font-medium justify-center">
                or
              </div>
            </div>
            <AdminSignInEmail
              setShowForgotPassord={setShowForgotPassord}
              showforgotPassword={showforgotPassword}
            />

            <div className="w-full flex flex-row text-[#000000]/60 font-normal text-[16px] font-poppins justify-center items-center mt-3 pt-3 border-t border-[#000000]/10">
              <div>No account ?</div>
              <div
                className="ml-3 text-black font-medium text-[16px]  font-poppins cursor-pointer underline"
                onClick={() => setShowSignUp(true)}
              >
                Signup with email
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;
