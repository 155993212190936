import { Link } from "react-router-dom";
import AdminDashboardLayout from "../AdminDashboardLayout";
import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "lucide-react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";

const termsData = [
  {
    heading: "Introduction",
    text: 'Welcome to Clearmind ( https://iamclearmind.alphaquark-case.com/ ). By accessing or using our services, you agree to comply with and be bound by the following Terms and Conditions ("T&C"). Please review them carefully.',
  },
  {
    heading: "Services Provided",
    text: "Clearmind provides stock advice and related financial information. Our services are intended to assist users in making informed investment decisions. However, we do not guarantee the accuracy, completeness, or timeliness of any information provided.",
  },
  {
    heading: "Not Investment Advice",
    text: "The content on this Website, including stock advice, is for informational purposes only and does not constitute financial, investment, or legal advice. You should consult with a financial advisor or other qualified professional before making any investment decisions.",
  },
  {
    heading: "User Responsibility",
    text: "Users are solely responsible for their investment decisions. Clearmind is not liable for any losses or damages resulting from the use of our services. Users must ensure that they understand the risks involved in stock trading.",
  },
  {
    heading: "Account Registration",
    text: "To use certain features of our Website, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update this information as necessary. You are responsible for maintaining the confidentiality of your account and password.",
  },
  {
    heading: "Payment and Subscriptions",
    text: "Some of our services may require payment or a subscription. By subscribing, you agree to pay all applicable fees as described on the Website. Fees are non-refundable, except as required by law.",
  },
  {
    heading: "Prohibited Conduct",
    text: "Users agree not to: Use the Website for any unlawful purpose. Post or transmit any content that is abusive, defamatory, or otherwise objectionable. Attempt to gain unauthorized access to the Website or its related systems.",
  },
  {
    heading: "Intellectual Property",
    text: "All content on the Website, including text, graphics, logos, and software, is the property of Clearmind or its content suppliers and is protected by intellectual property laws. You may not use any content without our express written permission.",
  },
  {
    heading: "Disclaimer of Warranties",
    text: 'The Website and its services are provided "as is" without any warranties, express or implied. Clearmind disclaims all warranties, including, but not limited to, the implied warranties of merchantability and fitness for a particular purpose.',
  },
  {
    heading: "Modifications to the T&C",
    text: "Clearmind reserves the right to modify these T&C at any time. Any changes will be effective immediately upon posting to the Website. Your continued use of the Website following the posting of revised T&C constitutes your acceptance of the changes.",
  },
  {
    heading: "Termination",
    text: "We reserve the right to terminate or suspend your access to the Website or services at our sole discretion, without notice or liability, for any reason, including breach of these T&C.",
  },
];

const TermsAndConditionsByAlpha = () => {
  const [user] = useAuthState(auth);
  console.log("user", user);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div className="flex flex-col relative  w-full lg:min-h-screen  bg-[#f9f9f9] ">
          <div className="border-[1px] border-[#000000]/10 px-[20px]  sm:px-[30px] py-[14px] lg:px-20 lg:py-3 flex items-center justify-between">
            <h2 className=" font-sans text-[18px] sm:text-[24px] md:text-[28px] font-bold md:leading-[60px] text-left ">
              Terms & Conditions by Clearmind
            </h2>
            {!isLoading && user === null && (
              <Link
                to="/"
                className=" bg-[#000000] text-white text-[16px] md:text-[18px] font-medium font-poppins rounded-md px-6 py-[6px] md:px-8 md:py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
              >
                Login
              </Link>
            )}
          </div>

          <ul className="mt-6 space-y-6 list-decimal list-inside px-[20px]  sm:px-[30px] pb-[10px] lg:px-20  lg:pb-3">
            {termsData.map((term, index) => (
              <li key={index} className="font-semibold text-[#161515CC]">
                <span className="font-sans text-[18px] font-bold leading-[40px] text-left text-[#161515CC]">
                  {term.heading}
                </span>
                <p className="font-poppins text-[16px] font-normal leading-[28px] text-left mt-2">
                  {term.text}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default TermsAndConditionsByAlpha;
